import { NavigateOptions, To, useLocation, useNavigate, useParams } from "react-router-dom";
import { useMemo } from "react";

export default function useRouter() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    return useMemo(() => {
        return {
            push: navigate,
            replace: (to: To, options?: NavigateOptions | undefined) => navigate(to, { replace: true, ...options }),
            goBack: () => navigate(-1),
            pathname: location.pathname,
            query: {
                ...params,
            },
            state: location.state,
            location,
        };
    }, [location, navigate]);
}
