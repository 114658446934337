import { colors, typo } from "@styles/index";
import styled from "styled-components";

export const ContentsWrap = styled.div<{
    chargingEndRemoteTypeCheck?: boolean;
}>`
    /* FIXME 12/16 */
    /* ${({ chargingEndRemoteTypeCheck }) => chargingEndRemoteTypeCheck && "min-height: 830px;"} */
    padding: 0 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
`;

export const TitleWrap = styled.div`
    /*  */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
`;

export const Title = styled.p`
    /*  */
    ${typo.HEADING_1}
`;

export const SubTitle = styled.small`
    /*  */
    ${typo.BODY_7}
    color: ${colors.GRAY5};
    text-align: center;
`;

export const LottieWrap = styled.div`
    /*  */
    width: 240px;
    height: 240px;
    position: relative;

    /* background: red; */
`;

export const LottieContentsWrap = styled.div`
    /*  */
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
`;

export const ChargingText = styled.p`
    /*  */
    display: flex;
    align-items: center;
    ${typo.DISPLAY_3}
`;

export const ChargingUnit = styled.span`
    /*  */
    ${typo.HEADING_5}
`;

export const ChargingTimer = styled.small`
    /*  */
    ${typo.BODY_9}
    color: ${colors.GRAY5};
`;

export const ChargingInformationWrap = styled.div`
    /*  */
    width: 100%;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 12px;
    border: 1px solid ${colors.GRAY9};
    background: ${colors.GRAY11};
`;

export const ContentWrap = styled.div`
    /*  */
    display: flex;
    justify-content: space-between;
`;

export const InformationTitle = styled.span`
    /*  */
    color: ${colors.GRAY6};
    ${typo.BODY_7}
`;

export const InformationContent = styled.span`
    /*  */
    color: ${colors.GRAY2};
    ${typo.BODY_7}
    display: flex;
    align-items: center;
    gap: 4.6px;
`;

export const Bar = styled.div`
    /*  */
    height: 1px;
    width: 100%;

    background: ${colors.GRAY9};
`;

export const ChargingAmountWrap = styled.div`
    /*  */
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ChargingAmountTitle = styled.span`
    /*  */
    ${typo.HEADING_6}
    color: ${colors.GRAY2};
`;

export const ChargingAmount = styled.span`
    /*  */
    ${typo.HEADING_4}
    color: ${colors.PRIMARY};
`;
