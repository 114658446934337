import { ICONS } from "@assets/index";
import { ApplicationButtonDataType, ChargingIconContextDataType } from "src/types/RendingPage";

export const ChargingIconContextData: ChargingIconContextDataType[] = [
    {
        title: "1. 충전대기",
        subTitle: "선결제 금액과 충전기 정보를 확인해요.",
        icon: ICONS.charging_standby_icon,
    },
    {
        title: "2. 결제하기",
        subTitle: "신용/체크 카드로 결제를 진행해요.",
        icon: ICONS.gold_icon,
    },
    {
        title: "3. 충전하기",
        subTitle: "커넥터를 연결하면 충전이 시작돼요.",
        icon: ICONS.charging_icon,
    },
];

export const ApplicationButtonData: ApplicationButtonDataType[] = [
    {
        label: "카카오내비",
        icon: ICONS.kakao_navi,
        link: "https://kakaonavi.kakao.com/launch/",
    },
    {
        label: "T맵",
        icon: ICONS.T_map,
        link: "",
    },
];
// FIXME deep link 지정
