import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { ErrorResponseType, RequestParams } from "./types";

const request = async <R, T>({ method, url, requestBody, requestParams }: RequestParams<R>): Promise<AxiosResponse<T>> => {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
    });

    // 헤더 설정
    const config: AxiosRequestConfig = {
        headers: {},
        params: requestParams,
    };

    // REQUEST Interceptor
    axiosInstance.interceptors.request.use(config => {
        return config;
    });

    // RESPONSE Interceptor
    axiosInstance.interceptors.response.use(
        response => {
            return response;
        },
        (error: AxiosError) => {
            console.log("NET WORK ERROR", error);
            if (axios.isAxiosError(error)) {
                const { response } = error;
                const { data }: { data: ErrorResponseType } = response as AxiosResponse;
                const commonError: ErrorResponseType = {
                    error: data?.error,
                    message: data?.message,
                    statusCode: data?.statusCode,
                };
                return Promise.reject(commonError);
            }
            return Promise.reject(error);
        },
    );

    switch (method) {
        case "get":
            return axiosInstance.get(url, config);
        case "post":
            return axiosInstance.post(url, requestBody, config);
        case "put":
            return axiosInstance.put(url, requestBody, config);
        case "delete":
            return axiosInstance.delete(url, {
                data: requestBody,
            });
        default:
            return Promise.reject(new Error("Invalid HttpMethod"));
    }
};

export default request;
