/* eslint-disable react/prop-types */
import { requestGuestsPayment } from "@apis/index";
import { PaymentRequest, PaymentsTermsResponse } from "@apis/types";
import { ICONS } from "@assets/index";
import Button from "@components/atoms/Button";
import { Icon as AllCheckIcon, Icon } from "@components/atoms/Button/IconButton";
import TermsItem from "@components/atoms/TermsItem";
import BottomButton from "@components/molecules/BottomButton";
import strings from "@constants/strings";
import urls from "@constants/urls";
import useGlobalModal from "@hooks/useGlobalModal";
import useGlobalPopup from "@hooks/useGlobalPopup";
import useRouter from "@hooks/useRouter";
import userStatusType from "@recoil/userStatusType";
import { colors, typo } from "@styles/index";
import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useMutation } from "react-query";
import { useRecoilState } from "recoil";
import styled, { css } from "styled-components";
import BasePopup from "../BasePopup";

export interface TermsPopupProps {
    isPopupActive?: boolean;
    TermsData?: PaymentsTermsResponse[];
    isLoading?: boolean;
    cardInfo: PaymentRequest;
}

function TermsPopup({ isPopupActive = false, isLoading = false, TermsData, cardInfo }: TermsPopupProps) {
    const { replace } = useRouter();
    const { hidePopup } = useGlobalPopup();
    const { showAlert } = useGlobalModal();
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(isPopupActive);
    const [isTermsDetail, setTermsDetail] = useState(false);
    const [termsDetailData, setTermsDetailData] = useState<string | undefined>();
    const [userState, setUserState] = useRecoilState(userStatusType);
    const [isAllTermsAgree, setIsAllTermsAgree] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState<string[]>([]);

    const { mutate } = useMutation(requestGuestsPayment, {
        onSuccess: data => {
            setUserState({
                ...userState,
                socialNumber: cardInfo?.socialNumber,
                transactionId: data?.transactionId,
            });
            replace(urls.Connecting);
        },
        onError: () => {
            showAlert({
                title: "결제를 실패했어요.",
                message: `다른 카드로 다시 결제해주세요.`,
            });
        },
        retry: false,
    });

    const closePopup = () => {
        setIsPopupOpen(false);
        setTimeout(() => hidePopup(), 300);
    };

    // 약관 상세
    const onTermsButtonPress = (detail: string) => {
        setTermsDetailData(detail);
        setTermsDetail(true);
    };

    // 결제하기
    const onTermsAgreeButtonPress = () => {
        if (isAllTermsAgree) {
            closePopup();
            mutate(cardInfo);
        } else {
            showAlert({
                title: "약관을 동의 해주세요.",
                message: "필수 약관을 동의 해주셔야 되요.",
            });
        }
    };

    // 전체 약관동의
    const allTermsAgreed = () => {
        if (!TermsData) return;
        if (isAllTermsAgree) {
            setAgreeTerms([]);
        } else {
            setAgreeTerms(() => TermsData.map(value => value.title));
        }
    };

    useEffect(() => {
        const Effect = setTimeout(() => setIsPopupOpen(true), 100);

        return () => clearTimeout(Effect);
    }, []);

    // 약관 동의 check
    useEffect(() => {
        const totallTermsLength = TermsData?.length;
        const termsLength = agreeTerms.length;

        if (totallTermsLength === termsLength) {
            setIsAllTermsAgree(true);
        } else {
            setIsAllTermsAgree(false);
        }
    }, [TermsData?.length, agreeTerms.length]);

    return (
        <>
            <BasePopup isOpen handleClose={closePopup} isPopupOpen={isPopupOpen}>
                <TermsBottomPopUp isActive={isPopupOpen}>
                    <TermsAllCheckWrap onClick={allTermsAgreed}>
                        {/* state로 아이콘 변경 */}
                        <AllCheckIcon icon={isAllTermsAgree ? ICONS.Controls.check_selected : ICONS.Controls.check_default} width={24} height={24} />
                        약관 전체동의
                    </TermsAllCheckWrap>

                    {/* icon 부분 단축 가능 */}
                    <TermsListWrap>
                        {isLoading ? (
                            <div>loading...</div>
                        ) : (
                            <>
                                {TermsData?.map(({ title, detail, id, isEssential, subTitle, subTtile }) => {
                                    return <TermsItem key={id} title={title} detail={detail} isEssential={isEssential} subTitle={subTitle || subTtile} onClick={onTermsButtonPress} agreeTerms={agreeTerms} setAgreeTerms={setAgreeTerms} />;
                                })}
                            </>
                        )}
                    </TermsListWrap>

                    <Button full size="extra" text={strings.ButtonText.PaymentsTermsAgree} onClick={onTermsAgreeButtonPress} disabled={!isAllTermsAgree} />
                </TermsBottomPopUp>
            </BasePopup>

            {/* 약관 상세 */}
            {isTermsDetail && (
                <Background
                    isOpen={true}
                    ariaHideApp={false}
                    style={{
                        overlay: {
                            background: "white",
                        },
                        content: {},
                    }}
                >
                    <StyledWrap>
                        <div
                            style={{
                                padding: "23px",
                                display: "flex",
                            }}
                        >
                            <Icon
                                icon={ICONS.header_close}
                                style={{
                                    marginLeft: "auto",
                                    padding: "9px",
                                }}
                                width={14}
                                height={14}
                                onClick={() => {
                                    setTermsDetail(false);
                                }}
                            />
                        </div>

                        {termsDetailData && (
                            <div
                                style={{
                                    padding: "10px 20px 250px",
                                    overflow: "auto",
                                    lineHeight: "20px",
                                    height: "100%",
                                    color: colors.GRAY6,
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: termsDetailData,
                                }}
                            />
                        )}
                        <BottomButton
                            buttonText={strings.ButtonText.Check}
                            pointerDisabled
                            onClick={() => {
                                setTermsDetail(false);
                            }}
                        />
                    </StyledWrap>
                </Background>
            )}
        </>
    );
}

export default TermsPopup;

const Background = styled(ReactModal)<{ isPopupOpen?: boolean }>`
    /*  */
    position: relative;
`;

const StyledWrap = styled.div`
    /*  */
    height: 100vh;
`;

const TermsBottomPopUp = styled.div<{ isActive: boolean }>`
    transition: all 0.5s;

    position: fixed;

    ${({ isActive }) => {
        if (isActive) {
            return css`
                bottom: 0;
            `;
        } else {
            return css`
                bottom: -304px;
                opacity: 0;
            `;
        }
    }}

    width: 100%;
    min-width: 320px;
    max-width: 720px;
    border-radius: 16px 16px 0 0;
    padding: 20px 20px 24px;
    background: white;
    z-index: 1;
    box-shadow: 0 -3px 12px 0 #00000040;
`;

const TermsAllCheckWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    ${typo.HEADING_6}
    padding: 16px 12px;
    border-radius: 12px;
    margin-bottom: 12px;
    background: ${colors.GRAY11};
    cursor: pointer;
`;

const TermsListWrap = styled.div`
    padding: 0 8px 0 12px;
    margin-bottom: 32px;
`;
