import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { colors } from ".";

export const GlobalStyle = createGlobalStyle`
    ${reset}
        /* other styles */
    * {
        box-sizing: border-box;
    }

    html, body {
        font-family: 'Pretendard';
        height: 100%;
        /* FIXME */
        background: white;

    }

    input {
        caret-color: ${colors.PRIMARY};
    }
`;
