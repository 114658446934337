import { colors, typo } from "@styles/index";
import React from "react";
import { ApplicationButtonDataType } from "src/types/RendingPage";
import styled from "styled-components";

interface AppButtonProps {
    data: ApplicationButtonDataType;
    onClick?: () => void;
}

const AppButton: React.FC<AppButtonProps> = ({ data, onClick }) => {
    const { icon, label } = data;
    return (
        <StyledDiv onClick={onClick}>
            <IconDiv icon={icon} />
            <StyledP>{label}</StyledP>
        </StyledDiv>
    );
};

export default AppButton;

const StyledDiv = styled.div`
    /*  */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 12px;
    width: 95.67px;
    height: 106px;
    cursor: pointer;
`;

const IconDiv = styled.div<{ icon: string }>`
    /*  */
    width: 70px;
    height: 70px;
    background: no-repeat center/cover url(${({ icon }) => icon});
`;

const StyledP = styled.p`
    /*  */
    ${typo.BODY_7}
    color: ${colors.GRAY5};
`;
