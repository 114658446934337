import React from "react";
import { colors, typo } from "@styles/index";
import styled, { css, CSSObject } from "styled-components";

export type ButtonTypes = "fill" | "line";

export type ButtonColor = "mainBlue" | "gray" | "black";

export type ButtonSize = "extra" | "large" | "regular" | "small" | "thin";

interface ButtonProps {
    text?: string;
    btnType?: ButtonTypes;
    size?: ButtonSize;
    color?: ButtonColor;
    disabled?: boolean;
    full?: boolean;
    pointerDisabled?: boolean;
    buttonRef?: React.RefObject<HTMLButtonElement>;

    style?: CSSObject;
    onClick?: () => void;
}

/**
 *
 * @param btnType "fill" | "line";
 * @param color "mainBlue" | "gray" | "black";
 * @param text string;
 * @param size "extra" | "large" | "regular" | "small" | "thin";
 * @param disabled boolean;
 * @param full boolean;
 * @param style string;
 *
 */
const Button: React.FC<ButtonProps> = ({ btnType = "fill", color = "mainBlue", text = "Button", size = "extra", disabled, full, onClick, style, pointerDisabled, buttonRef }) => {
    return (
        <StyledButton style={style} btnType={btnType} size={size} color={color} disabled={disabled} full={full} onClick={onClick} pointerDisabled={pointerDisabled} ref={buttonRef}>
            {text}
        </StyledButton>
    );
};

export default Button;

const StyledButton = styled.button<{
    btnType: ButtonTypes;
    size: ButtonSize;
    color: ButtonColor;
    disabled?: boolean;
    full?: boolean;
    pointerDisabled?: boolean;
}>`
    border: none;
    width: ${({ full }) => (full ? "100%" : "")};
    cursor: ${({ disabled, pointerDisabled }) => (disabled || pointerDisabled ? "no-allowed" : "pointer")}; // no-drop
    ${({ color, btnType }) => color && buttonColorCheck(color, btnType)}
    ${({ size }) => size && buttonSizeCheck(size)}
    ${({ disabled, color, btnType }) => disabled && buttonDisabledCheck(color, btnType)}
    ${typo.BUTTON2}

    transition: 0.1s linear;

    :focus {
        outline: none;
    }
`;

const buttonDisabledCheck = (color: ButtonColor, btnType: ButtonTypes) => {
    if (btnType === "fill") {
        switch (color) {
            case "black":
            case "gray": {
                return css`
                    background-color: ${colors.GRAY10};
                    color: ${colors.GRAY7};

                    :hover {
                        background-color: ${colors.GRAY10};
                    }
                `;
            }
            case "mainBlue": {
                return css`
                    background-color: ${colors.PRIMARY5};
                    color: ${colors.WHITE};

                    :hover {
                        background-color: ${colors.PRIMARY5};
                    }
                `;
            }
        }
    }
    if (btnType === "line") {
        switch (color) {
            case "gray": {
                return css`
                    color: ${colors.GRAY7};
                    border-color: ${colors.GRAY7};

                    :hover {
                        border-color: ${colors.GRAY7};
                    }
                `;
            }
            case "mainBlue": {
                return css`
                    color: ${colors.PRIMARY5};
                    border-color: ${colors.PRIMARY5};

                    :hover {
                        color: ${colors.PRIMARY5};
                        border-color: ${colors.PRIMARY5};
                    }
                `;
            }
            case "black": {
                return "";
            }
        }
    }
};

/**
 *
 * @param size "extra" | "large" | "regular" | "small" | "thin";
 * @returns css size Style
 */
const buttonSizeCheck = (size: ButtonSize) => {
    switch (size) {
        case "extra": {
            return css`
                border-radius: 12px;
                padding: 16px 24px;
                ${typo.BODY_6};
                ${typo.BUTTON2};
            `;
        }
        case "large": {
            return css`
                border-radius: 12px;
                padding: 12px 20px;
                ${typo.BODY_6};
            `;
        }
        case "regular": {
            return css`
                border-radius: 12px;
                padding: 12px 16px;
                ${typo.BODY_8};
            `;
        }
        case "small": {
            return css`
                border-radius: 8px;
                padding: 8px 12px;
                ${typo.BODY_8};
            `;
        }
        case "thin": {
            return css`
                border-radius: 8px;
                padding: 7px 10px;
                ${typo.BUTTON4};
            `;
        }
    }
};

const buttonColorCheck = (color: ButtonColor, btnType: ButtonTypes) => {
    if (btnType === "fill") {
        switch (color) {
            case "mainBlue": {
                return css`
                    background-color: ${colors.PRIMARY};
                    color: ${colors.WHITE};

                    :hover {
                        background-color: ${colors.PRIMARY2};
                    }

                    :active {
                        background-color: ${colors.PRIMARY1};
                    }
                `;
            }
            case "gray": {
                return css`
                    background-color: ${colors.GRAY10};
                    color: ${colors.GRAY4};

                    :hover {
                        background-color: ${colors.GRAY9};
                    }

                    :active {
                        background-color: ${colors.GRAY8};
                    }
                `;
            }
            case "black": {
                return css`
                    background-color: ${colors.GRAY3};
                    color: ${colors.GRAY11};

                    :hover {
                        background-color: ${colors.GRAY2};
                    }

                    :active {
                        background-color: ${colors.BLACK};
                    }
                `;
            }
        }
    }

    if (btnType === "line") {
        switch (color) {
            case "mainBlue": {
                return css`
                    background-color: ${colors.WHITE};
                    color: ${colors.PRIMARY};
                    border: 1px solid ${colors.PRIMARY};

                    :hover {
                        color: ${colors.PRIMARY2};
                        border-color: ${colors.PRIMARY2};
                    }

                    :active {
                        color: ${colors.PRIMARY1};
                        border-color: ${colors.PRIMARY1};
                    }
                `;
            }
            case "gray": {
                return css`
                    background-color: ${colors.WHITE};
                    color: ${colors.GRAY4};
                    border: 1px solid ${colors.GRAY8};

                    :hover {
                        border-color: ${colors.GRAY7};
                    }

                    :active {
                        border-color: ${colors.GRAY6};
                    }
                `;
            }
            case "black": {
                return "";
            }
        }
    }
};
