import globalPopupState, { GlobalPopupType } from "@recoil/globalPopup";
import { useSetRecoilState } from "recoil";

export default function useGlobalPopup() {
    const setPopup = useSetRecoilState(globalPopupState);

    const showPopup = ({ popupType, popupProps }: GlobalPopupType) => {
        setPopup({ popupType, popupProps });
    };
    const hidePopup = () => {
        setPopup({ popupType: null, popupProps: null });
    };

    const showLandingPagePopup = () => {
        showPopup({
            popupType: "ChargingNonMemberPopup",
            popupProps: {},
        });
    };

    return {
        showPopup,
        hidePopup,
        showLandingPagePopup,
    };
}
