export const PrecautionsData = [
    { content: "결제 전 충전소 및 충전기 정보를 꼭 확인해주세요.", key: 1 },
    {
        content: "완속충전기는 최대14시간, 급속충전기는 최대1시간까지만 사용할 수있어요.",
        key: 2,
    },
    {
        content: "충전에 어려움이 있으시다면 고객센터(1661-7766)로 연락주세요.",
        key: 3,
    },
];
