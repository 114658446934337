import { AlertModalProps } from "@components/molecules/Modal/AlertModal";
import { TwoBtnModalProps } from "@components/molecules/Modal/TwoBtnModal";
import { atom } from "recoil";

export interface GlobalModalType {
    modalType: "AlertModal" | "TwoBtnModal" | null;
    modalProps: AlertModalProps | TwoBtnModalProps | null;
}

const globalModalState = atom<GlobalModalType>({
    key: "globalModal",
    default: {
        modalType: null,
        modalProps: null,
    },
});

export default globalModalState;
