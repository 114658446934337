import React from "react";
import styled, { CSSObject } from "styled-components";

interface ButtonCommonProps {
    onClick?: () => void;
    style?: CSSObject;
}

interface IconProps extends ButtonCommonProps {
    icon: string;
    width?: number;
    height?: number;
    disabledIconCover?: boolean;
    disabledCursorPinter?: boolean;
}

/**
 * @param icon  string
 * @param width  number
 * @param height number
 * @param onClick  Fn
 * @description width, height 값 다양하게 변경 가능
 * @description 사용할 Icon, fn 내려줘서 다양하게 사용
 * @description 간단하게 사용하게 만든거임.
 *
 */
export const Icon: React.FC<IconProps> = ({ onClick, icon, width, height, style, disabledIconCover, disabledCursorPinter }) => {
    return <StyledSpan style={style} icon={icon} width={width} height={height} onClick={onClick} disabledIconCover={disabledIconCover} disabledCursorPinter={disabledCursorPinter} />;
};

const StyledSpan = styled.span<{
    icon: string;
    width?: number;
    height?: number;
    disabledIconCover?: boolean;
    disabledCursorPinter?: boolean;
}>`
    /*  */
    display: inline-block;
    width: ${({ width }) => (width ? width : 14)}px;
    height: ${({ height }) => (height ? height : 14)}px;
    min-width: ${({ width }) => (width ? width : 14)}px;
    min-height: ${({ height }) => (height ? height : 14)}px;

    border: none;
    outline: none;
    background: no-repeat ${({ disabledIconCover }) => (disabledIconCover ? "center" : "center/cover")} url(${({ icon }) => icon});
    transition: all 0.15s;

    cursor: ${({ disabledCursorPinter }) => (disabledCursorPinter ? "" : "pointer")};
`;
