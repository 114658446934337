import { CheckChargerResponse } from "@apis/types";
import Button from "@components/atoms/Button";
import AppButton from "@components/molecules/LandingPage/AppButton";
import strings from "@constants/strings";
import useGlobalModal from "@hooks/useGlobalModal";
import useGlobalPopup from "@hooks/useGlobalPopup";
import { ApplicationButtonData } from "@mock/RendingPage";
import useChargerInfoStore from "@recoil/useChargerInfoStore";
import { colors, typo } from "@styles/index";
import redirectApp from "@utils/redirectApp";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import BasePopup from "../BasePopup";

export interface ChargingNonMemberPopupProps {
    onClick?: () => void;
    handleClose?: () => void;
    isPopupActive?: boolean;
    setIsPopupActive?: React.Dispatch<React.SetStateAction<boolean>>;
    isRandomBoolean: boolean;
}

function ChargingNonMemberPopup({ onClick, isPopupActive, isRandomBoolean }: ChargingNonMemberPopupProps) {
    const [isPopupOpen, setIsPopupOpen] = useState(isPopupActive);
    const { hidePopup } = useGlobalPopup();
    const isChargerInfo = useRecoilValue(useChargerInfoStore);
    const { chargerInfo } = isChargerInfo;
    const { showAlert } = useGlobalModal();

    useEffect(() => {
        const Effect = setTimeout(() => setIsPopupOpen(true), 100);

        return () => clearTimeout(Effect);
    }, []);

    const closePopup = () => {
        setIsPopupOpen(false);
        setTimeout(() => hidePopup(), 300);
    };

    return (
        <BasePopup isOpen handleClose={closePopup} isPopupOpen={isPopupOpen}>
            <LandingContentsWrap isPopupOpen={isPopupOpen}>
                <Button text={strings.ChargingNonMember} btnType="line" color="gray" size="large" full onClick={onClick} />
                <StyledBar />
                <StyledP>{strings.OtherServices}</StyledP>
                <Column>
                    {(isRandomBoolean ? ApplicationButtonData : [...ApplicationButtonData].reverse()).map(data => {
                        return (
                            <AppButton
                                data={data}
                                key={data.label}
                                onClick={() => {
                                    if (!chargerInfo) {
                                        showAlert({
                                            title: "충전기 정보가 확실하지 않아요.",
                                            message: `다른 충전기를 사용하시거나\n다시한번 시도해주세요.`,
                                        });
                                    }

                                    const { simpleCode } = chargerInfo as CheckChargerResponse;

                                    redirectApp(data.label, simpleCode);
                                }}
                            />
                        );
                    })}
                </Column>
            </LandingContentsWrap>
        </BasePopup>
    );
}

export default ChargingNonMemberPopup;

const LandingContentsWrap = styled.div<{ isPopupOpen?: boolean }>`
    /*  */
    transition: all 0.3s ease;
    position: fixed;
    bottom: ${({ isPopupOpen }) => (isPopupOpen ? "0" : "-314")}px;

    width: 100%;
    min-width: 20rem;
    max-width: 45rem;
    height: 314px;

    padding: 32px 20px 24px;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 24px;

    background: white;
`;

export const StyledBar = styled.span`
    /*  */
    display: block;
    width: 100%;
    height: 1px;

    background-color: ${colors.GRAY9};
`;

const StyledP = styled.p`
    /*  */
    ${typo.BODY_7}
    color: ${colors.GRAY6};
    text-align: center;
`;

export const Column = styled.div`
    /*  */
    display: flex;
    justify-content: space-around;
`;
