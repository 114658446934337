export const TermsList = {
    /** 개인정보 수집 및 이용에 대한 안내 */
    PersonalInformation: `
    <h4>[개인정보 수집 및 이용에 대한 안내]</h4>
    <br/>
    <p>"갤럭시아머니트리(주)"(이하 "회사")는 "정보통신망 이용촉진 및 정보보호에 관한 법률" 및 "개인정보보호법"과 "전자상거래 등에서의 소비자 보호에 관한 법률"을 준수하고 있으며, 회사는 전자결제서비스이용자(이하 "이용자"라 합니다)로부터 수집한 개인정보를 아래와 같이 수집 및 이용합니다.</p>
    <br/>
    <h5>제1조 (개인정보의 수집 및 이용목적)</h5>
    <p>
      회사는 다음과 같은 목적 하에 "서비스"와 관련한 개인정보를 수집합니다.<br/>
      ① 이용자가 구매한 재화나 용역의 대금결제<br/>
      ② 이용자가 결제한 거래의 취소 또는 환불<br/>
      ③ 이용자가 결제한 거래의 청구 및 수납<br/>
      ④ 이용자가 결제한 거래의 현금영수증 발행 요청하는 경우 국세청 신고<br/>
      ⑤ 이용자가 수납한 거래대금의 정산<br/>
      ⑥ 이용자가 결제한 거래내역을 요청하는 경우 응대 및 확인<br/>
      ⑦ 이용자의 결제를 위한 고유번호 생성<br/>
      ⑧ 전자결제서비스가 불가능한 이용자와 불량, 불법 이용자의 부정 이용 방지<br/>
      ⑨ 기타 회사가 제공하는 이벤트 등 광고성 정보 전달 및 통계학적 특성에 따른 서비스 제공 10 이용자의 결제 대금 미납 시 채권의 회수<br/>
    </p>
     
    <br/>
    <h5>제2조 (수집하는 개인정보 항목)</h5>
    <p>
      ① 결제수단별 회사가 수집하는 개인정보의 항목은 다음과 같습니다.<br/>
      1) 신용카드 : 카드번호(3rd-마스킹), 승인번호, 카드사정보, 상품명<br/>
      2) 계좌이체 : 은행명, 상품명 등<br/>
      3) 상품권<br/>
      - 해피머니상품권 : 해피머니아이디(ID), 상품명<br/>
      - 도서문화상품권 : 북앤라이프아이디(ID), 상품명<br/>
      - 문화상품권 : 컬쳐랜드아이디(ID), 상품명<br/>
      - 스마트문상 : 상품권번호(PIN), 상품<br/>
      - 캐시플러스 : 상품권번호(PIN), 상품명<br/>
      - 에그머니 : 상품권번호(PIN), 상품명<br/>
      - 틴캐시 : 틴캐시아이디(ID), 상품권번호(PIN), 상품명<br/>
      4) 휴대폰 : 휴대폰번호, 이동통신사정보, 생년월일(+성별, 내/외국인), 상품명, 단말기 기종, 모바일 운영체제,<br/> 브라우저 정보, 사용자 인증키<br/>
      5) 유선전화결제_폰빌,KTm : KT전화번호, 생년월일(+성별, 내/외국인), 상품명, 휴대폰번호, 메가패스아이디<br/>
      6) ARS신용카드 : 카드번호(3rd-마스킹), 승인번호, 카드사정보, 상품명<br/>
      7) 모바일티머니 : 휴대폰번호, 이동통신사정보, 상품명<br/>
      8) 현금영수증 : 생년월일(+성별, 내/외국인), 휴대폰번호, 신용카드번호, 사업자번호<br/>
      9) 가상계좌 : 은행명, 상품명 등<br/>
      10) 머니트리 결제 : 휴대폰번호, 상품명<br/>
      ② 상기 명시된 개인정보 항목 이외의 "서비스" 이용과정이나 "서비스" 처리 과정에서 다음과 같은 추가 정보들이 자동 혹은 수동으로 생성되어 수집 될 수 있습니다.<br/>
      - 이용자 IP/Mac Address, e-mail, 서비스 접속 일시, 서비스 이용기록, 불량 혹은 비정상 이용기록, 결제기록<br/>
      ③ 기타, 회사는 서비스 이용과 관련한 대금결제, 물품배송 및 환불 등에 필요한 정보를 추가로 수집할 수<br/>
      있습니다.<br/>
    </p>
    <br/>
    <h5>제3조 (개인정보의 보유 및 이용기간)</h5>
    <p>
      이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기 합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존 합니다.<br/>
      ① 회사 내부 방침의 의한 정보보유<br/>
      1) 보존항목 : 서비스 상담 수집 항목(회사명, 고객명, 전화번호, E-mail, 상담내용 등)<br/>
      2) 보존이유 : 분쟁이 발생 할 경우 소명자료 활용<br/>
      3) 보존기간 : 상담 완료 후 6개월<br/>
      ② 관련법령에 의한 정보보유 상법, 전자상거래 등에서의 소비자보호에 관한 법률, 전자금융거래법 등 관련법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관련법령에서 정한 일정한 기간 동안 정보를 보관합<br/>니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.<br/>
      1) 계약 또는 청약철회 등에 관한 기록 : 보존기간: 5년 / 보존근거: 전자상거래 등에서의 소비자보호에 관한법률<br/>
      2) 대금결제 및 재화 등의 공급에 관한 기록 : 보존기간: 5년 / 보존근거: 전자상거래 등에서의 소비자보호에관한 법률 (단, 건당 거래 금액이 1만원 이하의 경우에는 1년간 보존 합니다).<br/>
      3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 보존기간: 3년 / 보존근거: 전자상거래 등에서의 소비자보호에 관한 법률<br/>
      4) 전자금융거래에 관한 기록 : 보존기간: 5년 / 보존근거: 전자금융거래법<br/>
      5) 방문에 관한 기록 : 보존기간: 3개월 / 보존근거: 통신비밀보호법<br/>
    </p>
    <br/>
    <h5>제4조 (이용자의 개인정보 파기)</h5>
    <p>
      제3자에게 제공 및 위탁된 개인정보의 보유, 이용기간이 만료된 개인정보는 아래와 같은 방법으로 파기합니<br/>다.<br/>
      ① 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제<br/>
      ② 종이에 출력된 개인정보는 분쇄기 또는 소각을 통하여 파기<br/>
    </p>
    <br/>
    <h5>제5조 (이용자의 개인정보의 수집 및 이용 거부)</h5>
    <p>이용자는 회사의 개인정보 수집 및 이용 동의를 거부할 수 있습니다. 단, 동의를 거부 하는 경우 서비스 결제가 정상적으로 완료 될 수 없음을 알려 드립니다.</p>
  `,

    /** 개인정보 제공 및 위탁에 대한 안내 */
    ProvidePersonalInformation: `
  <h4>[개인정보 제공 및 위탁에 대한 안내]</h4>
  <br/>
  <p>
    "갤럭시아머니트리(주)"(이하 "회사")는 "정보통신망 이용촉진 및 정보보호에 관한 법률" 및 "개인정보보호법"과 "전자상거래 등에서의 소비자 보호에 관한 법률"을 준수하고 있으며, 회사는 전자결제서비스이용자(이하 "이용자"라 합니다)로부터 수집한 개인정보를 아래와 같이 제3자에게 제공 및 위탁합니다.<br/>
    회사는 전자결제서비스를 제공함에 있어서 취득한 이용자의 개인정보를 개인정보의 이용목적에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이 누설하거나 제3자 제공 및 위탁하지 않습니다. 단, 이용자 보호를 위해 다음 각 호의 경우에는 관련정보를 제공할 수 있습니다.
  </p>

<br/>
<p>
  ① 이용자가 사전에 동의한 경우(이용자가 사전에 동의한 경우란, 서비스 이용 등을 위하여 이용자가 자발적으로 자신의 개인정보를 제3자에게 제공하는 것에 동의하는 것을 의미합니다).<br/>
  ② 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br/>
  ③ 전자결제시장 질서를 교란시키는 불법업체 혹은 불법행위자에 의한 민원발생의 경우 <br/>
  ④ 지인 사용 등 제3자 결제로 인한 민원발생의 경우<br/>
  ⑤ 기타 전자결제 불법행위가 발생하여 이용자 보호가 필요한 경우<br/>
</p>

<br/>
<h5>제1조 (개인정보의 제공)</h5>
<p>
  회사는 아래와 같이 이용자로부터 수집한 개인정보를 제3자에게 제공 합니다.<br/>
  <br/>
  ① 결제수단 사업자<br/>
  1) 신용카드 : 비씨, KB국민, 현대, 삼성, 신한, 롯데, 외환, 하나, NH농협, 씨티, 전북, 수협, 제주, 광주, 신협, 저축은행<br/>
  2) 계좌이체 : 은행(기업/국민/외환/NH농협/우리/SC제일/대구/부산/경남/우체국/신한), LGU+, 금융결제원<br/>
  3) 상품권<br/>
  - 해피머니상품권 : 해피머니아이엔씨<br/>
  - 도서문화상품권 : 북앤라이프<br/>
  - 문화상품권 : 컬쳐랜드<br/>
  - 스마트문상 : 컬쳐랜드<br/>
  - 에그머니 : 해피닷컴<br/>
  - 틴캐시 : 페이레터, 플레이통<br/>
  - 캐시플러스 : 갤럭시아머니트리<br/>
  4) 휴대폰 : 이동통신사(SKT, KT, LGU+), 다날, KG모빌리언스, 페이레터, NICE페이먼츠(주), (주)헥토파이낸셜, 씨 제이올리브네트웍스(주), (사)한국전화결제산업협회, (주)한국케이블텔레콤, LG헬로비전, (주)에스케이플레닛, (주) 티모넷, (주)섹타나인, (주)코밴, 코리아크레딧뷰로 주식회사, SCI평가정보(주)<br/>
  5) 전화결제_ARS폰빌,KTm : KT, 모빌리언스<br/>
  6) ARS신용카드 : KT, KCP, KSNET<br/>
  7) 모바일티머니 : 티모넷<br/>
  8) 현금영수증 : 국세청<br/>
  9) 가상계좌 : (주)쿠콘, (주)헥토파이낸셜<br/>
  10) 휴대폰 결제 간소화 서비스 : (사)한국전화결제산업협회, 다날, KG모빌리언스, 페이레터, KCP, (주)티모넷, 이용자의 거래 상대방<br/>
  11) 머니트리 결제 : 갤럭시아머니트리<br/>
  <br/>
  ② 개인정보를 제공받는 자의 개인정보 이용 목적<br/>
  1) 휴대폰 결제 간소화 서비스를 위한 본인인증 및 거래승인, 요금정산을 위한 거래정보전송, 수납 등 요금 정산 관련 업무<br/>
  2) 채권회수를 위한 개인식별정보(생년월일, 성별, 휴대전화번호 등), 미납정보<br/>
  3) 부정거래(스미싱, 휴대폰 현금화 등)의 방지 및 관련 자료 취합, 휴대폰결제 관련 주요 내용 안내<br/>
  <br/>
  ③ 결제수단별 제공하는 개인정보의 항목<br/>
  1) 신용카드 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 카드사명 등)<br/>
  2) 계좌이체 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 은행명 등)<br/>
  3) 상품권<br/>
  - 해피머니상품권 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 해피머니아이디, 상품권번호(PIN) 등)<br/>
  - 도서문화상품권 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 북앤라이프아이디 등)<br/>
  - 문화상품권 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 컬쳐랜드아이디 등)<br/>
  - 스마트문상 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 상품권번호(PIN) 등)<br/>
  - 에그머니 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 상품권번호(PIN) 등)<br/>
  - 틴캐시 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 틴캐시아이디, 상품권번호(PIN) 등)<br/>
  - 캐시플러스 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 상품권번호(PIN) 등)<br/>
  4) 휴대폰 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 휴대폰번호, 이동통신사정보 등)<br/>
  5) 전화결제_ARS폰빌 : 이용자의 서비스 거래정보(이용상점정보, 상품명, KT전화번호, 휴대폰번호, 메가패스아 이디 등)<br/>
  6) ARS신용카드 : 이용자의 서비스 거래정보 (이용상점정보, 상품명, 카드번호(3rd-마스킹), 카드사정보 등)<br/>
  7) 모바일티머니 : 이용자의 서비스 거래정보 (이용상점정보, 상품명, 휴대폰번호, 이동통신사 등)<br/>
  8) 현금영수증 : 이용자의 서비스 거래정보 (이용자상점정보, 거래금액 등)<br/>
  9) 가상계좌 : 이용자의 서비스 거래정보 (이용자상점정보, 상품명, 가상계좌번호, 거래금액 등)<br/>
  10) 휴대폰 결제 간소화 서비스 : 고유번호(결제정보와 연동된 간편결제 KEY), USER ID, 생년월일(+성별, 내/ 외국인), 성명, 휴대폰 번호, 이동통신사 등<br/>
  11) 머니트리 결제 : 이용자의 서비스 거래정보 (이용상점정보, 상품명, 휴대폰번호 등)<br/>
  ④ 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간<br/>
  : 해당 사업자가 이미 보유하고 있는 개인정보이기 때문에 별도로 저장하지 않음. 단, 법령의 규정에 의한 거래 내역 등을 보관(건당 거래금액이 1만원 이하의 경우 1년, 1만원 초과의 경우 5년)<br/>
  ⑤ 이용자의 거래 상대방<br/>
  1) 개인정보를 제공받는 자의 개인정보 이용 목적 : 거래 승인 확인, 취소 및 환불, 거래 대금의 정산, 거래 확인 요청에 대한 응대 및 확인<br/>
  2) 제공하는 개인정보의 항목 : 이용자의 서비스 거래 정보(각 결제 수단의 거래 승인 여부, 거래 승인 실패 원인, 결제 일시, 결제금액 등)<br/>
  3) 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간 : 법령의 규정에 의한 거래 내역을 보관(건당 거 래금액이 1만원 이하의 경우 1년, 1만원 초과의 경우 5년), 이용자의 거래 상대방에 대한 회원탈퇴 시 또는 이동통신사 이용계약 해지 시
</p>

<br/>
<p>
  제2조 (개인정보의 위탁)<br/>
  회사는 아래와 같이 이용자로부터 수집한 개인정보를 제3자에게 위탁 합니다.<br/>
  <br/>
  ① 개인정보 취급 위탁 받는자 : 효성ITX<br/>
  <br/>
  ② 개인정보 취급 위탁 받는자의 개인정보 이용목적<br/>
  : 회사의 고객상담업무를 위한 수납 등 요금정산 관련 업무<br/>
  <br/>
  ③ 결제수단별 제공하는 개인정보의 항목<br/>
  1) 신용카드 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 카드사명 등)<br/>
  2) 계좌이체 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 은행명 등)<br/>
  3) 상품권<br/>
  - 해피머니상품권 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 해피머니아이디, 상품권번호(PIN) 등) - 도서문화상품권 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 북앤라이프아이디 등)<br/>
  - 문화상품권 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 컬쳐랜드아이디 등)<br/>
  - 스마트문상 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 상품권번호(PIN) 등)<br/>
  - 에그머니 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 상품권번호(PIN) 등)<br/>
  - 틴캐시 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 틴캐시아이디, 상품권번호(PIN) 등) - 캐시플러스 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 상품권번호(PIN) 등)<br/>
  4) 휴대폰 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 휴대폰번호, 이동통신사정보, 생년월일, 성별 등) 5) 전화결제_ARS폰빌 : 이용자의 서비스 거래정보(이용상점정보, 상품명, KT전화번호, 휴대폰번호, 메가패스아 이디 등)<br/>
  6) ARS신용카드 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 카드번호(3rd-마스킹), 카드사정보 등)<br/>
  7) 모바일티머니 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 휴대폰번호, 이동통신사 등)<br/>
  8) 현금영수증 : 이용자의 서비스 거래정보(이용자상점정보, 거래금액 등)<br/>
  9) 가상계좌 : 이용자의 서비스 거래정보 (이용자상점정보, 상품명, 가상계좌번호, 거래금액 등)<br/>
  10) 휴대폰 결제 간소화 서비스 : 고유번호(결제정보와 연동된 간편결제 KEY), USER ID, 생년월일(+성별, 내/ 외국인), 성명, 휴대폰 번호, 이동통신사 등<br/>
  11) 머니트리 결제 : 이용자의 서비스 거래정보(이용상점정보, 상품명, 휴대폰번호 등)
</p>

<br/>
<p>
  ④ 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간<br/>
  : 해당 사업자가 이미 보유하고 있는 개인정보이기 때문에 별도로 저장하지 않음. 단, 법령의 규정에 의한 거래 내역 등을 보관(건당 거래금액이 1만원 이하의 경우 1년, 1만원 초과의 경우 5년)<br/>
  <br/>
  ⑤ 이용자의 거래 상대방<br/>
  1) 개인정보를 위탁받는 자의 개인정보 이용 목적 : 거래 승인 확인, 취소 및 환불, 거래 대금의 정산, 거래 확인 요청에 대한 응대 및 확인, 소액결제 미납요금에 대한 안내 및 회수<br/>
  2) 개인정보를 위탁하는 개인정보의 항목 : 이용자의 서비스 거래 정보(각 결제 수단의 거래 승인 여부, 거래 승인 실패 원인, 결제 일시, 결제금액, 소액결제 미납요금 등)<br/>
  3) 개인정보를 위탁 받는자의 개인정보 보유 및 이용 기간 : 법령의 규정에 의한 거래 내역을 보관(건당 거 래금액이 1만원 이하의 경우 1년, 1만원 초과의 경우 5년), 서비스 종료 시 또는 상담 후 폐기
</p>

<br/>
<p>
  제3조 (이용자의 개인정보의 제공 및 위탁 거부)<br/>
  이용자는 회사의 개인정보 제공 및 위탁 동의를 거부할 수 있습니다. 단, 동의를 거부 하는 경우 서비스 결 제가 정상적으로 완료 될 수 없음을 알려 드립니다.
</p>

<br/>
<p>
  제4조 (이용자의 개인정보 파기)<br/>
  제3자에게 제공 및 위탁된 개인정보의 보유, 이용기간<br/>이 만료된 개인정보는 아래와 같은 방법으로 파기합니 다.<br/>
  ① 전자적 파일형태로 저장된 개인정보는 기록을 재생<br/>할 수 없는 기술적 방법을 사용하여 삭제<br/>
  ② 종이에 출력된 개인정보는 분쇄기 또는 소각을 통하여 파기
</p>
  `,

    /** 전자금융 이용약관 */
    ElectronicFinance: `
  <p>제1장 총칙<br/></p>
  <br/>
  <br/>
  <p>
    제 1조 (목적)<br/>
    본 약관은 갤럭시아머니트리(이하 "회사"라 합니다)가 제공하는 전자지급결제대행서비스, 선불전자지급수단의 발행 및 관리서비스, 직불전자지급수단의 발행 및 관리서비스, 결제대금예치서비스 (이하 통칭하여 "전자금융 거래서비스"라 합니다)를 “이용자”가 이용함에 있어, "회사"와 "이용자" 간 권리, 의무 및 "이용자"의 서비스 이용절차 등에 관한 사항을 규정하는 것을 그 목적으로 합니다.<br/>
  </p>
  <br/>
  <p>
  제2조 (정의)<br/>
  (1) 본 약관에서 정한 용어의 정의는 아래와 같습니다.<br/>
    1 "전자금융거래"라 함은 "회사"가 "전자적 장치"를 통하여 금융상품 및 서비스를 제공(이하 "전자금융업무") 하고, “이용자”가 "회사"의 종사자와 직접 대면하거나 의사소통을 하지 아니하고 자동화된 방식으로 이를 이 용하는 거래를 말합니다.<br/>
    2 "전자지급수단"라 함은 전자자금이체, 직불전자지급수단, 선불전자지급수단, 전자화폐, 신용카드, 전자채 권 그 밖에 전자적 방법에 따른 지급 수단을 말합니다.<br/>
    3 “결제대금예치서비스”라 함은 이용자가 재화의 구입 또는 용역의 이용에 있어서 그 대가(이하 '결제대금'이라 한 다)의 전부 또는 일부를 재화 또는 용역을 공급받기 전에 미리 지급하는 경우, 회사가 이용자의 물품수령 또는 서 비스 이용 확인 시점까지 결제대금을 예치하는 서비스를 말합니다.<br/>
    4 ‘선불전자지급수단’이라 함은, 이전 가능한 금전적 가치가 전자적 방법으로 저장되어 발행된 증표 또는 그 증표 에 관한 정보로서 다음 각 목의 요건을 모두 갖춘 것을 말합니다. 다만, 전자화폐를 제외합니다.<br/>
    가. 발행인(대통령령이 정하는 특수관계인을 포함한다) 외의 제3자로부터 재화 또는 용역을 구입하고 그 대가를 지 급하는 데 사용될 것<br/>
    나. 구입할 수 있는 재화 또는 용역의 범위가 2개 업종(「통계법」 제22조 제1항의 규정에 따라 통계청장이 고시하 는 한국표준산업분류의 중분류상의 업종을 말한다. 이하 이 조에서 같다) 이상일 것<br/>
    5 ‘직불전자지급수단’이라 함은, 이용자와 가맹점 간에 전자적 방법에 따라 금융기관의 계좌에서 자금을 이체하는 등의 방법으로 재화 또는 용역의 제공과 그 대가의 지급을 동시에 이행할 수 있도록 금융기관 또는 전자금융업자 가 발행한 증표(자금을 융통 받을 수 있는 증표를 제외한다) 또는 그 증표에 관한 정보를 말합니다.<br/>
    6 “이용자”라 함은 이 약관에 동의하고 회사가 제공하는 전자금융거래 서비스를 이용하는 자를 말합니다.<br/>
    7 "접근매체"라 함은 "전자금융거래"에 있어서 "거래지시"를 하거나 이용자 및 거래내용의 진실성과 정확성 을 확보하기 위하여 사용되는 수단 또는 정보로서 "전자금융거래서비스"를 이용하기 위하여 "회사"에 등록된 아이디 및 비밀번호, 기타 "회사"가 지정한 수단을 말합니다.<br/>
    8 "거래지시"라 함은 "이용자"가 본 약관에 따라 "회사"에게 "전자금융거래"의 처리를 지시하는 것을 말합 니다.<br/>
    9 "오류"라 함은 "이용자"의 고의 또는 과실 없이 "전자금융거래"가 본 약관 또는 "이용자"의 "거래지시"에 따라 이행되지 아니한 경우를 말합니다.<br/>
    10 “가맹점”이라 함은 금융기관 또는 전자금융업자와의 계약에 따라 직불전자지급수단이나 선불전자지급수단 또는 전자화폐에 의한 거래에 있어서 이용자에게 재화 또는 용역을 제공하는 자로서 금융기관 또는 전자금융업자가 아 닌 자를 말합니다.<br/>
    11 "전자적 장치"라 함은 "전자금융거래" 정보를 전자적 방법으로 전송하거나 처리하는데 이용되는 장치로써 현금자동지급기, 자동입출금기, 지급용단말기, 컴퓨터, 전화기 그 밖에 전자적 방법으로 정보를 전송하거나 처리하는 장치를 말합니다.<br/>
    12 "아이디"란 "이용자"의 식별과 서비스 이용을 위하여 "이용자"가 설정하고 "회사"가 승인한 숫자와 문자 의 조합을 말합니다.<br/>
    13 "비밀번호"라 함은 "이용자"의 동일성 식별과 "이용자" 정보의 보호를 위하여 "이용자"가 설정하고 "회사" 가 승인한 숫자와 문자의 조합을 말합니다.<br/>
    (2) 본 조 및 본 약관의 다른 조항에서 정의한 것을 제외하고는 전자금융거래법 등 관련 법령에 정한 바에 따릅니다.<br/>
  </p>
  
  <br/>
  <br/>
  <p>
    제3조 (약관의 명시 및 변경)<br/>
    (1) "회사"는 “이용자”가 "전자금융거래"를 하기 전에 본 약관을 서비스 페이지에 게시하고 본 약관의 중요한 내용을 확인할 수 있도록 합니다.<br/>
    (2) "회사"는 "이용자"의 요청이 있는 경우 전자문서의 전송(이하 전자우편을 이용한 전송을 포함합니다), 모 사전송, 우편 또는 직접 교부의 방식에 의하여 본 약관의 사본을 "이용자"에게 교부합니다.<br/>
    (3) "회사"가 본 약관을 변경하는 때에는 그 시행일 1월 전에 변경되는 약관을 금융거래정보 입력화면 또는 서비스 홈페이지에 게시함으로써 "이용자"에게 공지합니다. 다만, 법령의 개정으로 인하여 긴급하게 약관을 변경하는 때에는 변경된 약관을 서비스 홈페이지에 1개월 이상 게시하고 이용자에게 통지합니다.<br/>
    (4) "회사"는 제(3)항의 공지나 통지를 할 경우, "이용자가 변경에 동의하지 아니한 경우 공지나 통지를 받은 날로부터 30일 이내에 계약을 해지할 수 있으며, 계약해지의 의사표시를 하지 아니한 경우에는 변경에 동의 한 것으로 본다."라는 취지의 내용을 공지합니다.<br/>
  </p>
  <br/>
  <br/>
  <p>
    제4조 (거래내용의 확인)<br/>
    (1) "회사"는 서비스 페이지를 통하여 "이용자"의 거래내용("이용자"의 "오류" 정정 요구사실 및 처리결과에 관한 사항을 포함합니다)을 확인할 수 있도록 하며, “이용자”가 거래내용에 대해 서면 교부를 요청하는 경우 에는 요청을 받은 날로부터 2주 이내에 모사전송, 우편 또는 직접 교부의 방법으로 거래내용에 관한 서면을 교부합니다.<br/>
    (2) "회사"는 제(1)항에 따른 "이용자"의 거래내용 서면 교부 요청을 받은 경우 "전자적 장치"의 운영장애, 그 밖의 사유로 거래내용을 제공할 수 없는 때에는 즉시 "이용자"에게 전자문서 전송의 방법으로 그러한 사유 를 알려야 하며, "전자적 장치"의 운영장애 등의 사유로 거래내용을 제공할 수 없는 기간은 제(1)항의 거래내 용에 관한 서면의 교부기간에 산입하지 아니합니다.<br/>
    (3) 제(1)항의 대상이 되는 거래내용 중 1만원을 초과하는 거래인 경우 5년간 보존하여야 하며, 5년인 것은 다음 각 호와 같습니다.<br/>
    1 거래계좌의 명칭 또는 번호<br/>
    2 "전자금융거래"의 종류 및 금액<br/>
    3 "전자금융거래"의 상대방에 관한 정보<br/>
    4 "전자금융거래"의 거래일시<br/>
    5 "전자적 장치"의 종류 및 "전자적 장치"를 식별할 수 있는 정보<br/>
    6 "회사"가 "전자금융거래"의 대가로 받은 수수료<br/>
    7 "이용자"의 출금 동의에 관한 사항<br/>
    8 "전자금융거래"의 신청 및 조건의 변경에 관한 사항<br/>
    9 “전자금융거래와 관련한 전자적 장치의 접속기록”<br/>
    (4) 제(1)항의 대상이 되는 거래내용 중 1만원 이하인 소액 전자금융거래인 경우 1년간 보존하여야 하며, 1년 인 것은 다음 각 호와 같습니다.<br/>
    1 "이용자"의 "오류" 정정 요구사실 및 처리결과에 관한 사항<br/>
    2 전자지급수단의 이용과 관련된 거래승인에 대한 기록<br/>
    (5) “이용자”가 제(1)항에서 정한 서면 교부를 요청하고자 할 경우 다음의 주소 및 전화번호로 요청할 수 있 습니다.<br/>
    1 주소: 서울시 강남구 광평로 281(수서동) 수서빌딩15층<br/>
    2 이메일 주소: center@billgate.net<br/>
    3 전화번호: 1566-0123<br/>
    제5조 ("거래지시"의 철회 등)<br/>
    (1) “이용자”가 "회사"의 "전자금융거래"를 한 경우, “이용자”는 지급의 효력이 발생하기 전까지 본 약관에서 정한 바에 따라 제4조 제5항에 기재된 연락처로 전자문서의 전송 또는 서비스 페이지 내 철회에 의한 방법 으로 "거래지시"를 철회할 수 있습니다. 단, 각 서비스 별 "거래지시" 철회의 효력 발생시기는 본 약관 제17 조, 제26조, 제31조, 제35조에서 정한 바에 따릅니다.<br/>
    (2) “이용자”는 전자지급의 효력이 발생한 경우에 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법 령상 청약 철회의 방법에 따라 결제대금을 반환 받을 수 있습니다.<br/>
  </p>
  <br/>
  <p>
    제6조 (추심이체의 출금 동의 및 철회)<br/>
    (1) "회사"는 "이용자"의 요청이 있는 경우 "이용자"의 계좌가 개설되어 있는 금융회사 등이 추심이체를 실행 할 수 있도록 금융회사 등을 대신하여 전자금융거래법령 등 관련 법령에 따른 방법으로 출금에 대한 동의를 진행합니다.<br/>
    (2) "회사"는 전 항에 따른 "이용자"의 동의 사항을 추심 이체를 실행하는 해당 금융회사 등에 제출합니다. (3) “이용자”는 "이용자"의 계좌의 원장에 출금기록이 끝나기 전까지 "회사" 또는 해당 금융회사 등에 제1항 의 규정에 따른 동의의 철회를 요청할 수 있습니다.<br/>
    (4) 전 항에도 불구하고 "회사" 또는 금융회사 등은 대량으로 처리하는 거래 또는 예약에 따른 거래 등의 경 우에는 미리 "이용자"과 정한 약정에 따라 동의의 철회시기를 달리 정할 수 있습니다.<br/>
    (5) “이용자”가 제3항에 따라 출금 동의 철회를 요청한 경우에도 “이용자”는 동의 철회에 대한 의사표시 이전 에 발생한 출금에 대해서는 이의를 제기할 수 없습니다.<br/>
  </p>
  <br/>
  <p>
    제7조 ("오류"의 정정 등)<br/>
    (1) “이용자”는 "전자금융거래서비스"를 이용함에 있어 "오류"가 있음을 안 때에는 "회사"에 대하여 그 정정을 요구할 수 있습니다.<br/>
    (2) "회사"는 전 항의 규정에 따른 "오류"의 정정 요구를 받은 때 또는 스스로 "전자금융거래"에 "오류"가 있 음을 안 때에는 이를 즉시 조사하여 처리한 후 정정 요구를 받은 날 또는 "오류"가 있음을 안 날부터 2주 이내에 그 결과를 문서, 전화 또는 전자우편으로 "이용자"에게 알려 드립니다. 다만, “이용자”가 문서로 알려 줄 것을 요청하는 경우에는 문서로 알려 드립니다.<br/>
  </p>
  <br/>
  <p>
    제8조 ("전자금융거래" 기록의 생성 및 보존)<br/>
    (1) "회사"는 “이용자”가 이용한 "전자금융거래"의 내용을 추적, 검색하거나 그 내용에 "오류"가 발생한 경우 에 이를 확인하거나 정정할 수 있는 기록을 생성하여 보존합니다.<br/>
    (2) 전 항의 규정에 따라 "회사"가 보존하여야 하는 기록의 종류 및 보존기간은 제4조 제(3)항 내지 제(4)항 에서 정한 바에 따릅니다.<br/>
    1 다음 각 목의 거래기록은 5년간 보존하여야 합니다.<br/>
    가. 제4조 제(3)항 제1호 내지 제8호에 관한 사항<br/>
    나. 해당 "전자금융거래"와 관련한 "전자적 장치"의 접속기록<br/>
    다. 건당 거래금액이 1만원을 초과하는 "전자금융거래"에 관한 기록<br/>
    2 다음 각 목의 거래기록은 1년간 보존하여야 합니다.<br/>
    라. 제4조 제(4)항 제1호에 관한 사항<br/>
    마. 건당 거래금액이 1만원 이하인 "전자금융거래"에 관한 기록<br/>
    바. 전자지급수단 이용과 관련된 거래승인에 관한 기록<br/>
    사. 기타 금융위원회가 고시로 정한 사항<br/>
  </p>
  <br/>
  <p>
    제9조 ("전자금융거래"정보의 제공금지)<br/>
    (1) "회사"는 "전자금융거래서비스"를 제공함에 있어서 취득한 "이용자"의 인적 사항, "이용자"의 계좌, "접근 매체" 및 "전자금융거래"의 내용과 실적에 관한 정보 또는 자료를 법령에 의하거나 "이용자"의 동의를 얻지 아니하고 제3자에게 제공, 누설하거나 업무상 목적 외에 사용하지 아니합니다.<br/>
    (2) "회사"는 “이용자”가 안전하게 "전자금융거래서비스"를 이용할 수 있도록 "이용자"의 개인정보보호를 위하 여 개인정보처리방침을 운용합니다. "회사"의 개인정보처리방침은 "회사"의 홈페이지 또는 서비스 페이지에 링크된 화면에서 확인할 수 있습니다.<br/>
  </p>
  <br/>
  <p>
    제10조 ("접근매체"의 선정과 사용 및 관리)<br/>
    (1) "회사"는 "전자금융거래서비스" 제공시 "접근매체"를 선정하여 "이용자"의 신원, 권한 및 "거래지시"의 내 용 등을 확인합니다.<br/>
    (2) “이용자”는 "접근매체"를 사용함에 있어서 다른 법률에 특별한 규정이 없는 한 다음 각 호의 행위를 하여 서는 아니됩니다.<br/>
    1 "접근매체"를 양도하거나 양수하는 행위<br/>
    2 대가를 수수•요구 또는 약속하면서 "접근매체"를 대여받거나 대여하는 행위 또는 보관•전달•유통하는 행 위<br/>
    3 범죄에 이용할 목적으로 또는 범죄에 이용될 것을 알면서 "접근매체"를 대여받거나 대여하는 행위 또는 보관•전달•유통하는 행위<br/>
    4 "접근매체"를 질권의 목적으로 하는 행위<br/>
    5 제1호부터 제4호까지의 행위를 알선하는 행위<br/>
    (3) “이용자”는 자신의 "접근매체"를 제3자에게 누설 또는 노출하거나 방치하여서는 안되며, "접근매체"의 도 용이나 위조 또는 변조를 방지하기 위하여 충분한 주의를 기울여야 합니다.<br/>
    (4) "회사"는 "이용자"로부터 "접근매체"의 분실이나 도난 등의 통지를 받은 때에는 그때부터 제3자가 그 "접 근매체"를 사용함으로 인하여 "이용자"에게 발생한 손해를 배상할 책임이 있습니다.<br/>
  </p>
  <br/>
  <p>
    제11조 ("회사"의 책임)<br/>
    (1) "회사"는 다음 각 호의 어느 하나에 해당하는 사고로 인하여 "이용자"에게 손해가 발생한 경우에는 그 손 해를 배상할 책임을 집니다.<br/>
    1 "접근매체"의 위조나 변조로 발생한 사고<br/>
    2 계약체결 또는 "거래지시"의 전자적 전송이나 처리 과정에서 발생한 사고<br/>
    3 전자금융거래를 위한 "전자적 장치" 또는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제2조 제1항 제1호에 따른 정보통신망에 침입하여 거짓이나 그 밖의 부정한 방법으로 획득한 "접근매체"의 이용으로 발 생한 사고<br/>
    (2) "회사"는 제(1)항에도 불구하고 다음 각 호의 어느 하나에 해당하는 경우에는 그 책임의 전부 또는 일부 를 “이용자”가 부담하게 할 수 있습니다.<br/>
    1 “이용자”가 "접근매체"를 제3자에게 대여하거나 그 사용을 위임한 경우 또는 양도나 담보의 목적으로 제 공한 경우<br/>
    2 “이용자”가 제3자가 권한 없이 "이용자"의 "접근매체"를 이용하여 "전자금융거래"를 할 수 있음을 알았거 나 쉽게 알 수 있었음에도 불구하고 자신의 "접근매체"를 누설하거나 노출 또는 방치한 경우<br/>
    3 "회사"가 보안강화를 위하여 "전자금융거래"시 요구하는 추가적인 보안조치를 “이용자”가 정당한 사유 없 이 거부하여 제(1)항 제3호에 따른 사고가 발생한 경우<br/>
    4 “이용자”가 제3호에 따른 추가적인 보안조치에 사용되는 매체•수단 또는 정보에 대하여 누설•노출 또는 방치하거나 제3자에게 대여하거나 그 사용을 위임한 행위 또는 양도나 담보의 목적으로 제공하는 행위를 하 여 제(1)항 제3호에 따른 사고가 발생한 경우<br/>
    5 법인(중소기업기본법 제2조 제2항에 의한 소기업을 제외합니다)인 "이용자"에게 손해가 발생한 경우로서 "회사"가 사고를 방지하기 위하여 보안절차를 수립하고 이를 철저히 준수하는 등 합리적으로 요구되는 충분 한 주의의무를 다한 경우<br/>
    (3) "회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체의 사유가 발생하여 "전자금융거래서비스"의 제공을 일시적으로 중단할 경우에는 "회사"의 홈페이지 또는 서비스 페이지를 통하여 "이용자"에게 중단 일정 및 중 단 사유를 사전에 공지합니다.<br/>
  </p>
  <br/>
  <p>
    제12조 (분쟁처리 및 분쟁조정)<br/>
    (1) “이용자”는 "회사"의 서비스 페이지 하단에 게시된 분쟁처리 담당자에 대하여 "전자금융거래"와 관련한 의견 및 불만의 제기, 손해배상의 청구 등의 분쟁처리를 요구할 수 있습니다.<br/>
    - 담당자: 고객만족팀 팀장<br/>
    - 연락처(전화번호, 전자우편주소): 02-6005-1615, center@billgate.net<br/>
    (2) “이용자”가 "회사"에 대하여 분쟁처리를 신청한 경우에는 "회사"는 15일 이내에 이에 대한 조사 또는 처 리 결과를 "이용자"에게 안내합니다.<br/>
    (3) “이용자”는 "전자금융거래"의 처리에 관하여 이의가 있을 때에는 금융위원회의 설치 등에 관한 법률에 따 른 금융감독원의 금융분쟁조정위원회 또는 소비자기본법에 따른 한국소비자원의 소비자분쟁조정위원회에 분 쟁조정을 신청할 수 있습니다.<br/>
  </p>
  <br/>
  <p>
    제13조 ("회사"의 안정성 확보 의무)<br/>
    "회사"는 "전자금융거래"가 안전하게 처리될 수 있도록 선량한 관리자로서의 주의를 다하며, "전자금융거래" 의 안전성과 신뢰성을 확보할 수 있도록 "전자금융거래"의 종류 별로 전자적 전송이나 처리를 위한 인력, 시 설, "전자적 장치" 등의 정보기술부문 및 전자금융업무에 관하여 금융위원회가 정하는 기준을 준수합니다.<br/>
  </p>
  <br/>
  <p>
    제14조 (약관 외 준칙)<br/>
    (1) "회사"와 "이용자" 사이에 개별적으로 합의한 사항이 이 약관에 정한 사항과 다를 때에는 그 합의사항을 이 약관에 우선하여 적용합니다.<br/>
    (2) 본 약관에서 정하지 아니한 사항에 대하여는 머니트리 서비스약관, 머니트리 이용자약관, 이 밖에 개별약 관에서 정한 바에 따릅니다.<br/>
    (3) 본 약관 및 전 항의 약관에서 정하지 아니한 사항(용어의 정의를 포함합니다)에 대하여는 전자금융거래 법, 전자상거래 등에서의 소비자보호에 관한 법률, 여신전문금융업법 등 소비자보호 관련 법령 및 개별 약관 에서 정한 바에 따릅니다.<br/>
  </p>
  <br/>
  <p>
    제15조 (관할)<br/>
    "회사"와 "이용자" 간에 발생한 분쟁에 관한 관할은 민사소송법에서 정한 바에 따릅니다.<br/>
  </p>
  <br/>
  <br/>
  제2장 전자지급결제대행서비스<br/>
  <br/>
  <p>
    제16조 (정의)<br/>
    회사가 제공하는 전자지급결제대행서비스는 지급결제수단에 따라 다음과 같이 구별됩니다<br/>
    (1) 신용카드결제대행서비스 : 이용자가 결제대금의 지급을 위하여 제공한 지급결제수단이 신용카드인 경우 회사가 전자결제시스템을 통하여 신용카드 지불정보를 송,수신하고 결제대금의 정산을 대행하거나 매개하는
    서비스를 말합니다.<br/>
    (2) 계좌이체 결제대행서비스 : 이용자가 결제대금을 회사의 전자결제시스템을 통하여 금융기관에 등록 정산 을 대행하거나 매개하는 서비스를 말합니다. 한 자신의 계좌에서 출금하여 원하는 계좌로 이체할 수 있는 실시간 송금 서비스를 말합니다.<br/>
    (3) 가상계좌 결제대행서비스 : 이용자가 결제대금을 현금으로 결제하고자 경우 회사의 전자결제시스템을 통 하여 자동 으로 이용자만의 고유한 일회용 계좌의 발급을 통하여 결제대금의 지급이 이루어지는 서비스를 말합니다.<br/>
    (4) 기타 : 회사가 제공하는 서비스로서 지급결제수단의 종류에 따라 ‘휴대폰 결제대행서비스’, ‘ARS결제대행 서비스’, ‘상품권결제대행서비스’등이 있습니다.<br/>
  </p>
  <br/>
  <p>
    제17조 ("거래지시"의 철회)<br/>
    (1) “이용자”가 "전자지급결제대행서비스"를 이용한 경우, “이용자”는 "거래지시"된 금액의 정보에 대하여 수 취인의 계좌가 개설되어 있는 금융회사 또는 "회사"의 계좌의 원장에 입금기록이 끝나거나 "전자적 장치"에 입력이 끝나기 전까지 "거래지시"를 철회할 수 있습니다.<br/>
    (2) "회사"는 "이용자"의 "거래지시"의 철회에 따라 지급거래가 이루어지지 않은 경우 수령한 자금을 "이용자 "에게 반환하여야 합니다.<br/>
  </p>
  <br/>
  <p>
    제18조 (한도 등)<br/>
    "회사"의 정책 및 결제업체(이동통신사, 카드사 등)의 기준에 따라 "이용자"의 결제수단별 월 누적 결제액 및 결제한도가 제한될 수 있습니다.<br/>
  </p>
  <br/>
  <br/>
  제3장 선불전자지급수단의 발행 및 관리서비스<br/>
  <br/>
  <p>
    제19조 (정의)<br/>
    본 장에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
    (1) "선불전자지급수단"이라 함은 머니트리캐시 등 "회사"가 발행 당시 미리 "이용자"에게 공지한 전자금융거 래법상 선불전자지급수단을 말합니다.<br/>
    (2) "유상 선불전자지급수단”이라 함은 "선불전자지급수단" 중 “이용자”가 "회사"에 직접 대가를 지급하고 구 매하여 “이용자”가 완전한 소유권, 처분권 등을 보유한 충전 포인트 말합니다.<br/>
    (3) "무상 선불전자지급수단”이라 함은 "선불전자지급수단" 중 "유상 선불전자지급수단" 이외의 것을 말합니 다.<br/>
    (4) “선불충전금”이란 이용자가 선불전자지급수단 발행의 대가로 회사에 지급한 금액으로서 유상으로 구매하 여 충전한 선불전자지급수단의 잔액을 말합니다.<br/>
  </p>
  <br/>
  <p>
    제20조 (적용 범위)<br/>
    “이용자”가 “회사”가 발행한 “선불전자지급수단”으로 다른 형태의 상품권[공정거래위원회의 신유형 상품권 표 준약관 제2조 제1항 각 호의 유형(전자형, 모바일, 온라인 상품권)이 아닌 것에 한함]을 구매한 경우 해당 상 품권의 사용 및 환불 등에 관해서는 “회사”의 명시적인 표시가 없는 한 이 약관의 적용을 받지 않습니다.<br/>
  </p>
  <br/>
  <p>
    제21조 (발행)<br/>
    “회사”는 “선불전자지급수단”의 발행 시 소요되는 제반비용을 부담합니다. 다만, “무상 선불전자지급수단”은 본 조의 적용을 받지 않습니다.<br/>
  </p>
  <br/>
  <p>
    제22조(“선불전자지급수단”의 보유)<br/>
    “이용자”는 “회사”가 정한 방법과 절차에 따라 “유상 선불전자지급수단”을 충전하거나, "무상 선불전자지급수 단"을 서비스 등에서의 활동으로 적립 받는 등의 방법으로 “선불전자지급수단”을 보유할 수 있으며, “회사”는 이에 관한 구체적인 사항을 본 약관 또는 “선불전자지급수단” 관련 서비스 페이지를 통해 공지합니다.<br/>
  </p>
  <br/>
  <p>
    제23조 (“선불전자지급수단”의 사용 등)<br/>
    (1) “이용자”는 "회사" 또는 “회사”의 가맹점에서 “선불전자지급수단”을 사용할 수 있으며, 이에 관한 구체적 인 사항을 본 약관 또는 “선불전자지급수단” 관련 서비스 페이지를 통해 공지합니다.<br/>
    (2) “이용자”는 잔액 범위 내에서 사용 횟수에 제한 없이 “선불전자지급수단”을 사용 할 수 있으며, 사용 시 사용 금액만큼 “선불전자지급수단”이 차감됩니다.<br/>
  </p>
  <br/>
  <p>
    제24조 (“선불전자지급수단”의 양도 등)<br/>
    (1) “이용자”는 "회사”가 공지한 방법과 절차에 따라 “선불전자지급수단”을 양도할 수 있습니다. 다만 "무상 선불전자지급수단"의 경우 "회사"가 양도를 제한할 수 있습니다.<br/>
    (2) “이용자”가 전 항에 따라 “선불전자지급수단”을 양도한 뒤에 전기통신금융사기(보이스피싱 등)를 이유로 “회사”에 피해구제를 신청한 경우 “회사”는 “선불전자지급수단”을 양도받은 “이용자”의 “선불전자지급수단” 사용, 환급 등을 제한할 수 있습니다.<br/>
    (3) “회사”는 전 항과 관련하여 “이용자”에게 발생한 손해에 대하여는 책임지지 않습니다.<br/>
  </p>
  <br/>
  <p>
    제25조 (환급 등)<br/>
    (1) “이용자”는 “선불전자지급수단” 구매 후 “회사”에 청약 철회 또는 환불을 신청할 수 있으며, 이 경우 권면 금액의 60%이상 사용(1만원 이하의 경우 80% 이상 사용)하고 회사가 명시한 소정의 절차로 요청한 경우 , “회사”는 “선불전자지급수단”에 기록된 잔액을 “이용자”에게 지급합니다. 다만, “무상 선불전자지급수단”의 경 우 환불 대상에서 제외됩니다.<br/>
    (2) “회사”는 “이용자”가 “선불전자지급수단”의 구매 청약 철회 또는 환불을 신청할 경우 구매 금액 또는 잔 액을 지급하기 위하여 필요한 비용을 수수료로 청구할 수 있으며, 이 경우 수수료를 차감 후 잔액을 지급할 수 있습니다.<br/>
    (3) “회사”는 제2항에 의한 수수료가 “이용자”가 유상으로 구매한 “선불전자지급수단”에 기록된 잔액보다 많 을 경우에는 “이용자”의 환불청구를 제한 할 수 있습니다.<br/>
    (4) 다음 각 호의 어느 하나에 해당하는 사유로 인하여 “이용자”가 “선불전자지급수단” 잔액의 환급을 요청 하는 경우 “회사”는 “이용자”의 “선불전자지급수단” 등을 회수하고 잔액 전부를 지급합니다. 이 경우 “회사” 는 “이용자”에게 환급 수수료를 청구 하지 않습니다.<br/>
    1 천재지변 등의 사유로 가맹점이 물품 또는 용역을 제공하기가 곤란하여 “선불전자 지급수단”의 사용이 불가한 경우<br/>
    2 “선불전자지급수단”의 결함으로 가맹점이 재화 또는 용역을 제공하지 못하게 된 경우<br/>
    (5) “이용자”는 선불전자지급수단의 유효기간이 경과한 경우에도 구매일 최종 충전일로부터 5년까지 “회사”에 게 “선불전자지급수단”의 미사용 부분에 대한 반환을 청구할 수 있으며, “회사”는 잔액의 90%를 “이용자”에 게 지급합니다. 다만, “무상 선불전자지급수단”은 본 항의 적용을 받지 않습니다.<br/>
    (6) “회사”에 환불을 요청할 수 있는 권리는 “선불전자지급수단”의 최종 소지자가 보유합니다. 다만, “선불전 자지급수단” 최종 소지자가 “회사”에 환불을 요청할 수 없는 경우에 한하여 “선불전자지급수단” 구매자가 “회사”에 환불을 요청할 수 있으며 “회사”가 구매자에게 환불한 경우 “회사”는 환불에 관한 책임을 면합니다.<br/>
  </p>
  <br/>
  <p>
    제26조 ("거래지시"의 철회)<br/>
    (1) “이용자”가 "선불전자지급수단"을 이용하여 자금을 지급하는 경우, “이용자”는 "거래지시"된 금액의 정보가 수취인이 지정한 "전자적 장치"에 도달하기 전까지 "거래지시"를 철회할 수 있습니다.<br/>
    (2) "회사"는 "이용자"의 "거래지시"의 철회에 따라 지급거래가 이루어지지 않은 경우 수령한 자금을 "이용자 "에게 반환하여야 합니다.<br/>
  </p>
  <br/>
  <p>
    제27조 ("선불전자지급수단"의 한도 등)<br/>
    (1) "회사"는 "이용자"의 "선불전자지급수단" 보유 한도를 관련 법령 및 "회사"의 정책에 따라 정할 수 있으 며, “이용자”는 "회사"가 정한 그 보유 한도 내에서만 "선불전자지급수단"을 보유할 수 있습니다.<br/>
    (2) "회사"는 서비스 페이지 등을 통하여 전 항의 최대 보유 한도를 공지합니다.<br/>
  </p>
  <br/>
  <p>
    제28조 (유효기간 등)<br/>
    (1) "회사"는 "이용자"에 대하여 "선불전자지급수단"에 대한 유효기간을 설정할 수 있으며, “이용자”는 "회사" 가 정한 유효기간 내에서만 "선불전자지급수단"을 사용할 수 있습니다.<br/>
    (2) "회사"는 서비스 페이지 등을 통하여 전 항의 유효기간 설정 여부 및 그 기간을 공지합니다. 단, “회사”가 유효기간을 달리 정하지 않은 경우에는 본조 제4항의 소멸시효기간을 유효기간으로 봅니다.<br/>
    (3) “회사”가 유효기간을 소멸시효기간보다 짧게 정한 경우, “이용자”는 “회사”에게 유효기간 내에서 유효기간 의 연장을 요청할 수 있습니다. 다만, “무상 선불전자지급수단”은 본 항의 적용을 받지 않습니다.<br/>
    (4) “회사”가 유상으로 발행한 "선불전자지급수단"의 경우 충전일로부터 5년간 사용하지 않으면 소멸되며, “무 상 선불전자지급수단”은 서비스 페이지 등을 통해 별도 공지합니다.<br/>
    (5) “회사”는 유효기간이 도래하기 7일 전 통지를 포함하여 3회 이상 이용자에게 유효기간의 도래, 유효기간 의 연장 가능여부와 방법 등을 이메일 또는 문자메세지 등의 방법으로 통지하여야 합니다. 다만, “무상 선불 전자지급수단”은 본 항의 적용을 받지 않습니다.<br/>
  </p>
  <br/>
  <p>
    제29조 (선불충전금의 관리 및 관련 공시)<br/>
    (1) “회사”는 이용자의 선불충전금을 고유재산과 구분하여 외부 금융기관에 신탁하거나 지급보증보험에 가입 하여야 합니다.<br/>
    (2) 회사는 매 영업일 마다 선불충전금 총액과 신탁금 등 실제 운용중인 자금 총액의 상호 일치 여부를 점 검하여야 하며, 매 분기말(분기 종료 후 10일 이내) 선불충전금 규모 및 지급보증보험 가입여부, 부보 금액 등을 홈페이지(www.imoneytree.co.kr) 등에 공시하여야 합니다.<br/>
    (3) “회사”는 다음 각 호의 어느 하나에 해당하는 경우 선불충전금을 신탁회사 및 보험회사 등을 통하여 이 용자에게 우선 지급하여야 합니다. 이 경우 1개월 이내에 그 사실과 선불충전금의 지급시기, 지급장소, 그 밖에 선불충전금의 지급과 관련된 사항을 둘 이상의 일간신문에 공고하고, 인터넷 홈페이지 등을 통하여 공 시하여야 합니다.<br/>
    1. 등록이 취소되거나 말소된 경우<br/>
    2. 해산 또는 선불전자지급수단 발행 및 관리 업무를 폐지한 경우 3. 파산선고를 받은 경우<br/>
    4. 선불전자지급수단 발행 및 관리 업무의 정지명령을 받은 경우 5. 제1호부터 제4호까지에 준하는 사유가 발생한 경우<br/>
  </p>
  <br/>
  <p>
    제30조 (선불충전금의 지급보증보험)<br/>
    (1) 회사는 선불충전금의 50% 이상을 이용자를 수익자로 하여 신탁하거나 이용자를 피보험자로 하여 지급보 증보험에 가입하여야 합니다.<br/>
    (2) 회사는 선불충전금을 원칙적으로 신탁하되, 불가피한 경우 지급보증의 방식을 혼합할 수 있습니다.<br/>
    (3) 회사가 선불충전금을 신탁하는 경우 대상금액을 전액 신탁하여야 하며, 일부를 지급준비금 용도로 예치 할 수 없습니다.<br/>
    (4) 회사는 신탁 또는 지급보증보험에 가입하지 않은 나머지 선불충전금을 운용하는 경우 안전자산으로 운 용하여야 합니다.<br/>
  </p>
  <br/>
  <p>
    제31조 (착오송금에 관한 사항)<br/>
    (1) 고객이 착오로 수취금융회사, 수취계좌번호 등을 잘못 기재하거나 입력하여 수취인에게 선불전자지급수 단 등의 자금이 이동(이하 ‘착오송금’이라 합니다.)된 경우, 회사에 통지하여 회사 또는 수취 금융회사 등을 통해 수취인에게 연락하여 착오 송금액 반환을 요청할 수 있습니다.<br/>
    (2) 회사는 수취인에 대한 연락 사실, 수취인의 반환의사 유무, 수취인이 반환의사가 없는 경우 그 사유 등 고객 요청사항에 대한 처리결과 또는 관련 처리 진행상황을 고객이 전항의 ‘착오송금’ 발생사실을 회사에 통 지한 날로부터 15일 이내에 고객에게 알려야 합니다.<br/>
    (3) 회사 또는 수취 금융회사를 통한 착오송금 반환 요청에도 수취인이 반환하지 않는 경우, 고객은 「예금자 보호법」 제5장(착오송금 반환지원)에 따라 예금보험공사에 착오송금 반환지원 제도 이용을 신청할 수 있습 니다. 단, 개정 「예금자보호법」 시행일인 ’21.7.6. 이후 발생한 착오송금에 대해 신청 가능하며, 연락처를 통 한 송금, SNS회원간 송금 거래 등 예금보험공사가 수취인의 실지명의를 취득할 수 없는 거래는 반환지원 신 청이 제한됩니다.<br/>
    (4) 회사는 예금보험공사가 착오송금 반환지원 업무의 원활한 수행을 위해 회사에 착오송금 수취인의 반환 불가 사유, 실지명의, 주소 및 연락처, 착오송금 발생 현황 등의 자료를 요청하는 경우 정당한 사유가 없으 면 이에 따라야 합니다.<br/>
    (5) 고객이 예금보험공사를 통해 착오송금 반환지원을 신청한 내역이 다음 각 호에 해당하는 경우 관련 법 령에 따라 예금보험공사의 지원 절차가 중단될 수 있습니다.<br/>
    1. 고객이 거짓이나 부정한 방법으로 반환지원을 신청한 경우<br/>
    2. 착오송금이 아님이 객관적인 자료로 확인되는 경우<br/>
    3. 신청일 이전 반환 지원을 신청한 착오송금과 관련된 소송 등이 진행 중이거나 완료된 경우 4. 그 밖에 예금보험위원회가 인정하는 경우<br/>
  </p>
  <br/>
  <br/>
  <p>
    제4장 직불전자지급수단의 발행 및 관리서비스 제32조 (정의)<br/>
    본 장에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
    (1) "직불전자지급수단"이라 함은 "이용자"과 "판매자" 간에 전자적 방법에 따라 금융회사의 계좌에서 자금을 이체하는 방법으로 재화 등의 제공과 그 대가의 지급을 동시에 이행할 수 있는 전자금융거래법상 직불전자 지급수단을 말합니다.<br/>
  </p>
  <br/>
  <p>
    제33조 ("거래지시"의 철회)<br/>
    (1) “이용자”가 "직불전자지급수단"을 이용하여 자금을 지급하는 경우, “이용자”는 "거래지시"된 금액의 정보 에 대하여 수취인의 계좌가 개설되어 있는 금융회사의 계좌의 원장에 입금기록이 끝나기 전까지 "거래지시" 를 철회할 수 있습니다.<br/>
    (2) "회사"는 "이용자"의 "거래지시"의 철회에 따라 지급거래가 이루어지지 않은 경우 수령한 자금을 "이용자 "에게 반환하여야 합니다.<br/>
  </p>
  <br/>
  <p>
    제34조 ("직불전자지급수단"의 한도 등)<br/>
    (1) "회사"는 “이용자”가 "직불전자지급수단"을 이용하여 재화 등을 구매할 수 있는 최대 이용한도(1회, 1일 이용한도 등)를 관련 법령 및 "회사"의 정책에 따라 정할 수 있으며, “이용자”는 "회사"가 정한 그 이용한도 내에서만 "직불전자지급수단"을 사용할 수 있습니다.<br/>
    (2) "회사"는 서비스 페이지 등을 통하여 전 항의 최대 이용한도를 공지합니다.<br/>
  </p>
  <br/>
  <br/>
  제5장 결제대금예치서비스<br/>
  <p>
    제35조 (정의)<br/>
    본 장에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
    (1) "결제대금예치서비스"라 함은 서비스 페이지에서 이루어지는 "선불식 통신판매"에 있어서, "회사"가 “이용 자”가 지급하는 결제대금을 예치하고, 배송이 완료되는 등 구매가 확정된 후 재화 등의 대금을 "판매자"에게 지급하는 서비스를 말합니다.<br/>
    (2) "선불식 통신판매"라 함은 “이용자”가 재화 등을 공급받기 전에 미리 대금의 전부 또는 일부를 지급하는 방식의 통신판매를 말합니다.<br/>
  </p>
  <br/>
  <p>
    제36조 (예치된 결제대금의 지급방법)<br/>
    (1) "이용자"(그 "이용자"의 동의를 얻은 경우에는 재화 등을 공급받을 자를 포함합니다. 이하 제2항 및 제3 항에서 동일합니다)은 재화 등을 공급받은 사실을 재화 등을 공급받은 날부터 3영업일 이내에 "회사"에 통 보하여야 합니다.<br/>
    (2) "회사"는 "이용자"로부터 재화 등을 공급받은 사실을 통보 받을 경우 "회사"가 정한 기일 내에 "판매자" 에게 결제대금을 지급합니다.<br/>
    (3) "회사"는 “이용자”가 재화 등을 공급받은 날부터 3영업일이 지나도록 정당한 사유의 제시 없이 그 공급받 은 사실을 "회사"에 통보하지 아니하는 경우에 "이용자"의 동의 없이 "판매자"에게 결제대금을 지급할 수 있 습니다.<br/>
    (4) "회사"가 "판매자"에게 결제대금을 지급하기 전에 “이용자”가 그 결제대금을 환급 받을 정당한 사유가 발 생한 경우에는 그 결제대금을 "이용자"에게 환급합니다.<br/>
  </p>
  <br/>
  <p>
    제37조("거래지시"의 철회)<br/>
    (1) “이용자”가 "결제대금예치서비스"를 이용한 경우, “이용자”는 "거래지시"된 금액의 정보에 대하여 수취인 의 계좌가 개설되어 있는 금융회사 또는 “회사”의 계좌의 원장에 입금기록이 끝나거나, “전자적 장치”에 입력 이 끝나기 전까지 “거래지시”를 철회할 수 있습니다.<br/>
    (2) "회사"는 "이용자"의 “거래지시”의 철회에 따라 지급거래가 이루어지지 않은 경우 수령한 자금을 "이용자" 에게 반환하여야 합니다.<br/>
  </p>
  <br/>
  -부칙–<br/>
  <p>1. 이 약관은 2021년 7월 6일부터 시행합니다.<br/></p>
  `,
};

export const termsData = [
    {
        key: 1,
        title: "개인정보 수집 및 이용",
        detail: TermsList.PersonalInformation,
        isEssential: true,
    },
    {
        key: 2,
        title: "개인정보 제공 및 위탁",
        detail: TermsList.ProvidePersonalInformation,
        isEssential: true,
    },
    {
        key: 3,
        title: "전자금융 이용약관",
        detail: TermsList.ElectronicFinance,
        isEssential: true,
    },
];
