import { css } from "styled-components";

export const typo = {
    /** Display */
    DISPLAY_1: css`
        font-weight: 700;
        font-size: 48px;
        line-height: 68px;
    `,
    DISPLAY_2: css`
        font-weight: 700;
        font-size: 40px;
        line-height: 56px;
    `,
    DISPLAY_3: css`
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
    `,

    /** Heading */
    HEADING_1: css`
        font-weight: 700;
        font-size: 26px;
        line-height: 38px;
    `,
    HEADING_2: css`
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    `,
    HEADING_3: css`
        font-weight: 700;
        font-size: 22px;
        line-height: 34px;
    `,
    HEADING_4: css`
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
    `,
    HEADING_5: css`
        font-weight: 700;
        font-size: 19px;
        line-height: 26px;
    `,
    HEADING_6: css`
        font-weight: 700;
        font-size: 17px;
        line-height: 24px;
    `,
    HEADING_7: css`
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    `,
    HEADING_8: css`
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
    `,
    HEADING_9: css`
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
    `,
    HEADING_10: css`
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
    `,
    HEADING_11: css`
        font-weight: 700;
        font-size: 11px;
        line-height: 16px;
    `,

    /** Body */
    BODY_1: css`
        font-weight: 400;
        font-size: 26px;
        line-height: 38px;
    `,
    BODY_2: css`
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
    `,
    BODY_3: css`
        font-weight: 400;
        font-size: 22px;
        line-height: 34px;
    `,
    BODY_4: css`
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
    `,
    BODY_5: css`
        font-weight: 400;
        font-size: 19px;
        line-height: 26px;
    `,
    BODY_6: css`
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
    `,
    BODY_7: css`
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    `,
    BODY_8: css`
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
    `,
    BODY_9: css`
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
    `,
    BODY_10: css`
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
    `,
    BODY_11: css`
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
    `,

    /** Button */
    BUTTON1: css`
        font-weight: 600; // FIXME 500
        font-size: 19px;
        line-height: 26px;
    `,
    BUTTON2: css`
        font-weight: 600; // FIXME 500
        font-size: 17px;
        line-height: 24px;
    `,
    BUTTON3: css`
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
    `,
    BUTTON4: css`
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
    `,
    BUTTON5: css`
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
    `,

    /** Label */
    LABEL1: css`
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
    `,
    LABEL2: css`
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
    `,
    LABEL3: css`
        font-weight: 500;
        font-size: 11px;
        line-height: 12px;
    `,

    /** Paragraph */
    PARAGRAPH1: css`
        font-weight: 400;
        font-size: 17px;
        line-height: 28px;
    `,
    PARAGRAPH2: css`
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
    `,
};

export const colors = {
    PRIMARY: "#26B0EB",
    PRIMARY1: "#008BC7",
    PRIMARY2: "#0099DB",
    PRIMARY3: "#6BD3FF",
    PRIMARY4: "#A6E4FF",
    PRIMARY5: "#BEECFF",
    PRIMARY6: "#D8F3FF",
    PRIMARY7: "#EDFAFF",
    PRIMARY8: "#F7FDFF",

    BLACK: "#000000",
    GRAY2: "#222729",
    GRAY3: "#353C3F",
    GRAY4: "#4A5256",
    GRAY5: "#5E696E",
    GRAY6: "#6E777C",
    GRAY7: "#AEB7BC",
    GRAY8: "#CFD6D9",
    GRAY9: "#E7EDF0",
    GRAY10: "#F1F4F6",
    GRAY11: "#F7F9FA",
    WHITE: "#ffffff",

    RED: "#FF334B",
    RED1: "#E5172F",
    RED2: "#FF697A",
    RED3: "#FF96A3",
    RED4: "#FCC5CB",
    RED5: "#FFEDEF",

    ORANGE: "#FF9500",
    ORANGE1: "#E17700",
    ORANGE2: "#FFAA33",
    ORANGE3: "#FFBF66",
    ORANGE4: "#FFDFB3",
    ORANGE5: "#FFF4E5",

    YELLOW: "#FFCC00",
    YELLOW1: "#E1AE00",
    YELLOW2: "#FFD633",
    YELLOW3: "#FFE066",
    YELLOW4: "#FFF0B3",
    YELLOW5: "#FFF7D9",

    GREEN: "#15BD66",
    GREEN1: "#18A352",
    GREEN2: "#35DB80",
    GREEN3: "#64E8A4",
    GREEN4: "#93EDBF",
    GREEN5: "#EBFAEF",

    BLUE: "#007AFF",
    BLUE1: "#005CE1",
    BLUE2: "#3395FF",
    BLUE3: "#66AFFF",
    BLUE4: "#B3D7FF",
    BLUE5: "#E5F2FF",

    PURPLE: "#8556E3",
    PURPLE1: "#6B3CC9",
    PURPLE2: "#A17DF5",
    PURPLE3: "#BD9FF5",
    PURPLE4: "#D4C4F5",
    PURPLE5: "#FFEBFF",
};

export const shadow = {
    DP1: css`
        box-shadow: 0px 2px 4px 0px #353a3f0a;
    `,
    DP2: css`
        box-shadow: 0px 2px 16px 0px #353a3f0a;
    `,
    DP4: css`
        box-shadow: 0px 2px 20px 0px #353a3f14;
    `,
    DP8: css`
        box-shadow: 0px 4px 32px 0px #353a3f1a;
    `,
    DP16: css`
        box-shadow: 0px 8px 32px 0px #353a3f29;
    `,
};
