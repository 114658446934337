import { requestChargerState } from "@apis/index";
import Lottie from "@components/atoms/Lottie";
import BottomButton from "@components/molecules/BottomButton";
import Layout from "@components/organisms/Layout";
import strings from "@constants/strings";
import urls from "@constants/urls";
import useGlobalModal from "@hooks/useGlobalModal";
import useRouter from "@hooks/useRouter";
import useChargerInfoStore from "@recoil/useChargerInfoStore";
import userStatusType from "@recoil/userStatusType";
import { colors, typo } from "@styles/index";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import styled from "styled-components";

const Connecting: React.FC = () => {
    const { replace } = useRouter();
    const { showAlert, hideModal } = useGlobalModal();
    const [userStatus, setUserStatusType] = useRecoilState(userStatusType);
    const [, setChargerInfo] = useRecoilState(useChargerInfoStore);
    const { deviceType, transactionId } = userStatus;

    /**
     * 비회원 충전 상태 조회 (20초 간격 체크)
     */
    useQuery(["chargerState", transactionId], () => requestChargerState(transactionId), {
        onSuccess: data => {
            setChargerInfo({
                chargingStartAt: data.chargingStartAt,
                chargerInfo: data.chargerInfo,
            });
            replace(urls.Charging);
        },
        onError: () => {
            // 에러 모달 필요?
        },
        refetchInterval: 10000,
        retry: false,
        refetchOnWindowFocus: false,
    });

    // 취소
    const onCancelPress = () => {
        showAlert({
            title: strings.Modal.Title.StopCharge,
            message: strings.Modal.Message.StopCharge,
            handleConfirm: () => {
                cancelFlow();
            },
        });
    };

    // 취소 or 뒤로가기
    const cancelFlow = () => {
        hideModal();
        replace(urls.LandingPage);
        setUserStatusType({
            deviceType,
        });
    };

    // 커넥터 연결 타임아웃
    useEffect(() => {
        const connectingTimer = setTimeout(() => {
            showAlert({
                title: strings.Modal.Title.ConnectionTimeOut,
                message: strings.Modal.Message.ReCertify_Charger,
                handleConfirm: () => {
                    cancelFlow();
                },
            });
        }, 120000);

        return () => clearTimeout(connectingTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Layout back disabledMainLayoutPadding onBackClick={onCancelPress}>
            <ContainerWrap>
                <ContentsWrap>
                    <TextWrap>
                        <Title>충전 커넥터를 연결해 주세요.</Title>
                        <SubTitle>커넥터를 연결하면 충전이 시작돼요.</SubTitle>
                    </TextWrap>
                    <LottieContainer>
                        <Lottie lottieName="충전기_연결" />
                    </LottieContainer>
                </ContentsWrap>
            </ContainerWrap>

            <BottomButton buttonColor="gray" buttonText={strings.ButtonText.Cancel} onClick={onCancelPress} />
        </Layout>
    );
};

export default Connecting;

const ContentsWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const TextWrap = styled.div`
    text-align: center;
`;

const Title = styled.div`
    ${typo.HEADING_1}
    color: ${colors.GRAY2};
    margin-bottom: 12px;
`;

const SubTitle = styled.small`
    color: ${colors.GRAY5};
    ${typo.BODY_7}
`;

const ContainerWrap = styled.div`
    height: 76vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LottieContainer = styled.div`
    padding-right: 48px;
`;
