import { ICONS } from "@assets/index";
import { Icon } from "@components/atoms/Button/IconButton";
import Lottie from "@components/atoms/Lottie";
import BottomButton from "@components/molecules/BottomButton";
import Precautions from "@components/molecules/Precautions";
import Layout from "@components/organisms/Layout";
import strings from "@constants/strings";
import { ChargingPrecautionsData } from "@mock/Charging";
import { Bar, ChargingAmount, ChargingAmountTitle, ChargingAmountWrap, ChargingInformationWrap, ContentsWrap, ContentWrap, InformationContent, InformationTitle, LottieWrap, SubTitle, Title, TitleWrap } from "@pages/Charging/styles";
import { colors } from "@styles/index";
import React from "react";

const ChargingCompleted: React.FC = () => {
    return (
        <Layout back disabledMainLayoutPadding>
            <ContentsWrap>
                <TitleWrap>
                    <Title>충전이 완료되었어요!</Title>
                    <SubTitle>
                        커넥터를 분리한 후 제자리에 놓아주세요.
                        <br /> 다음 사용자를 위해 출차해주세요.
                    </SubTitle>
                </TitleWrap>

                <LottieWrap>
                    <Lottie lottieName="충전완료" />
                </LottieWrap>

                <ChargingInformationWrap>
                    <ContentWrap>
                        <InformationTitle>충전소명</InformationTitle> <InformationContent>서울마포 LC타워</InformationContent>
                    </ContentWrap>
                    <ContentWrap>
                        <InformationTitle>충전소 ID</InformationTitle> <InformationContent>12345678</InformationContent>
                    </ContentWrap>
                    <ContentWrap>
                        <InformationTitle>충전량</InformationTitle> <InformationContent>0.08kW</InformationContent>
                    </ContentWrap>
                    <ContentWrap>
                        <InformationTitle>충전 시작</InformationTitle> <InformationContent>00:00:00</InformationContent>
                    </ContentWrap>
                    <ContentWrap>
                        <InformationTitle>충전 완료</InformationTitle> <InformationContent>00:00:00</InformationContent>
                    </ContentWrap>
                    <ContentWrap>
                        <InformationTitle>결제수단</InformationTitle>
                        <InformationContent>
                            <Icon width={22.8} height={14.4} icon={ICONS.Bank.shinhan} />
                            KB국민카드 (123*)
                        </InformationContent>
                    </ContentWrap>
                    <Bar />
                    <ChargingAmountWrap>
                        <ChargingAmountTitle>충전 금액</ChargingAmountTitle>
                        <ChargingAmount>2,075원</ChargingAmount>
                    </ChargingAmountWrap>
                </ChargingInformationWrap>
            </ContentsWrap>

            {/* 충전 종료시 버튼으로 교체 */}
            <Precautions data={ChargingPrecautionsData} background={`${colors.GRAY11}`} />
            <BottomButton buttonColor="mainBlue" buttonText={strings.ButtonText.AppNextTime} />
        </Layout>
    );
};

export default ChargingCompleted;
