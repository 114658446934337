import { LabelTypes } from "src/types/RendingPage";

const redirectApp = (label: LabelTypes, simpleCode: string) => {
    exeDeepLink(label, simpleCode);
};

export default redirectApp;

function exeDeepLink(label: LabelTypes, simpleCode: string) {
    let url = "";
    switch (label) {
        case "T맵": {
            url = `http://www.tmap.co.kr/tmap2/mobile/tmap.jsp?scheme=tmap&host=evcharge&extra=%7B%22serviceId%22:%22tmap%22,%22serviceType%22:%22chkQrCode%22,%22payload%22:%22https://qr.everon.co.kr/v1/landing?qrcode=EV${simpleCode}%22%7D`;
            break;
        }
        case "카카오내비": {
            url = `https://carner.kakaomobility.com/web2app/bridge?action=kakaonavi%3A%2F%2Fcar_electro%3Fdata%3D%7B%22code%22%3A%22EV${simpleCode}%22%7D%26ref%3Deveron_qr`;
            break;
        }
    }
    window.location.href = url;
}
