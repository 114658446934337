import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

export type DeviceType = "android" | "iphone" | null;

const { persistAtom } = recoilPersist();

interface UserStatusType {
    // 사용자 디바이스
    deviceType: DeviceType;
    // 거래 아이디
    transactionId?: string;
    // 거래 종료 key값
    socialNumber?: string;
}

const userStatusType = atom<UserStatusType>({
    key: "userStatusType",
    default: {
        deviceType: null,
        transactionId: "",
        socialNumber: "",
    },
    effects_UNSTABLE: [persistAtom],
});

export default userStatusType;
