import urls from "@constants/urls";
import useRouter from "@hooks/useRouter";
import useChargerInfoStore from "@recoil/useChargerInfoStore";
import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";

const GuestPage: React.FC = () => {
    const { replace } = useRouter();
    const { chargerInfo } = useRecoilValue(useChargerInfoStore);
    /**
     * isGuest parameter 가 있고 chargerInfo가 들어오면 자동으로 비회원결제로 이동
     */
    useEffect(() => {
        if (chargerInfo) {
            replace(urls.CheckInformationCharging + "?isGuest=1");
        }
    }, [chargerInfo]);

    return <div>redirecting...</div>;
};
export default GuestPage;
