import { AlertModalProps } from "@components/molecules/Modal/AlertModal";
import { TwoBtnModalProps } from "@components/molecules/Modal/TwoBtnModal";
import globalModalState, { GlobalModalType } from "@recoil/globalModal";
import { useSetRecoilState } from "recoil";

/**
 * - modal의 편의성 위해 조금더 상세히 조작!
 */

export default function useGlobalModal() {
    const setModal = useSetRecoilState(globalModalState);

    const showModal = ({ modalType, modalProps }: GlobalModalType) => {
        setModal({ modalType, modalProps });
    };
    const hideModal = () => {
        setModal({ modalType: null, modalProps: null });
    };

    const showAlert = ({ title, message, buttonText, handleConfirm }: AlertModalProps) => {
        showModal({
            modalType: "AlertModal",
            modalProps: {
                title,
                message,
                buttonText,
                handleConfirm: () => (handleConfirm ? handleConfirm() : hideModal()),
                handleClose: () => (handleConfirm ? handleConfirm() : hideModal()),
            },
        });
    };

    const showConfirmModal = ({ title, firstButtonText, secondButtonText, onClickFirstButton, onClickSecondButton }: TwoBtnModalProps) => {
        showModal({
            modalType: "TwoBtnModal",
            modalProps: {
                title,
                firstButtonText,
                secondButtonText,
                onClickFirstButton: () => onClickFirstButton(),
                onClickSecondButton: () => onClickSecondButton(),
                handleClose: () => (onClickFirstButton ? onClickFirstButton() : hideModal()),
            },
        });
    };

    return {
        showModal,
        hideModal,
        showAlert,
        showConfirmModal,
    };
}
