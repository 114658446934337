// 사업자 번호 번호 포맷팅
const formatNumber = (value: string) => {
    const number = value.replace(/\D/g, "");

    if (number.length <= 3) {
        return number;
    }

    if (number.length <= 5) {
        return `${number.slice(0, 3)} ${number.slice(3)}`;
    }

    return `${number.slice(0, 3)} ${number.slice(3, 5)} ${number.slice(5)}`;
};

export default formatNumber;
