import { colors, typo } from "@styles/index";
import React from "react";
import styled, { css, CSSObject } from "styled-components";

export type ButtonSize = "large" | "small";

export type TextButtonColor = "black" | "mainBlue";

interface TextButtonProps {
    text?: string;
    size?: ButtonSize;
    color?: TextButtonColor;
    line?: boolean;
    disabled?: boolean;
    onClick?: () => void;

    style?: CSSObject;
}

/**
 *
 * @param text string
 * @param color "black" | "mainBlue"
 * @param size "large" | "small"
 * @param line boolean
 * @param disabled boolean
 * @param onClick () => void
 *
 * @param style string
 */
const TextButton: React.FC<TextButtonProps> = ({ text = "Button", color = "mainBlue", size = "large", line = false, disabled = false, style, onClick }) => {
    return (
        <StyledButton color={color} size={size || ""} line={line} disabled={disabled} style={style} onClick={onClick}>
            <span>{text}</span>
        </StyledButton>
    );
};

export default TextButton;

const StyledButton = styled.button<TextButtonProps>`
    border: none;
    background-color: white;

    cursor: ${({ disabled }) => (disabled ? "not-drop" : "pointer;")};

    ${({ color }) => color && ButtonColorCheck(color)}
    ${({ size }) => size && buttonSizeCheck(size)}
    > span {
        position: relative;

        /* underLine */
        ${({ line }) =>
            line === true &&
            css`
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0px;
                    bottom: 0px;
                    width: 100%;
                    height: 1px;
                    background-color: currentcolor;
                }
            `}
    }
`;

const ButtonColorCheck = (color: TextButtonColor) => {
    switch (color) {
        case "black": {
            return css`
                color: ${colors.GRAY6};

                :hover {
                    color: ${colors.GRAY2};
                }

                :disabled {
                    color: ${colors.GRAY7};
                }
            `;
        }
        case "mainBlue": {
            return css`
                color: ${colors.PRIMARY};

                :hover {
                    color: ${colors.PRIMARY2};
                }

                :active {
                    color: ${colors.PRIMARY1};
                }

                :disabled {
                    color: ${colors.GRAY7};
                }
            `;
        }
    }
};

const buttonSizeCheck = (size: ButtonSize) => {
    if (size === "large") {
        return css`
            ${typo.BUTTON3}
        `;
    }
    if (size === "small") {
        return css`
            ${typo.BODY_10}
        `;
    }
};
