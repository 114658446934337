import { useBodyScrollLock } from "@hooks/useBodyScrollLock";
import globalModalState from "@recoil/globalModal";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import AlertModal from "../AlertModal";
import TwoBtnModal from "../TwoBtnModal";

const MODAL_TYPES = {
    AlertModal: "AlertModal",
    TwoBtnModal: "TwoBtnModal",
} as const;

const MODAL_COMPONENTS: any = {
    [MODAL_TYPES.AlertModal]: AlertModal,
    [MODAL_TYPES.TwoBtnModal]: TwoBtnModal,
};

function GlobalModal() {
    const { modalProps, modalType } = useRecoilValue(globalModalState);
    const { lockScroll, openScroll } = useBodyScrollLock();

    useEffect(() => {
        if (modalType) {
            lockScroll();
        } else {
            openScroll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalType]);

    const renderComponent = () => {
        if (!modalType) {
            return null;
        }
        const ModalComponent = MODAL_COMPONENTS[modalType];
        return <ModalComponent {...modalProps} />;
    };

    return <>{renderComponent()}</>;
}

export default GlobalModal;
