import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

interface BaseModalProps {
    children?: React.ReactNode;
    isOpen: boolean;
    handleClose?: () => void;
}

const BaseModal: React.FC<BaseModalProps> = ({ children, isOpen, handleClose }) => {
    const customStyles = {
        overlay: {
            background: "#00000040",
        },
    };

    return (
        <StyledModal isOpen={isOpen} onRequestClose={handleClose} style={customStyles} ariaHideApp={false}>
            {children}
        </StyledModal>
    );
};

export default BaseModal;

const StyledModal = styled(ReactModal)`
    /*  */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 312px;
    width: 100%;
    max-width: 20.9375rem;
    background: white;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0 4px 32px rgba(53, 58, 63, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    z-index: 2;

    :focus {
        outline: none;
    }
`;
