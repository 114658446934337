import { colors, typo } from "@styles/index";
import React from "react";
import styled, { css } from "styled-components";

interface EffectiveCardInputProps {
    placeholder?: string;
    children: React.ReactNode;
    isActive?: boolean;
    onClick?: () => void;
    onBlur?: () => void;
}

const EffectiveCardInput: React.FC<EffectiveCardInputProps> = ({ placeholder, children, isActive, onClick, onBlur }) => {
    return (
        <StyledWrap isActive={isActive} onBlur={onBlur}>
            <Placeholder isActive={isActive} onClick={onClick}>
                {placeholder}
            </Placeholder>

            <Column>{children}</Column>
        </StyledWrap>
    );
};

export default EffectiveCardInput;

const StyledWrap = styled.div<{ isActive?: boolean }>`
    /*  */
    width: 100%;
    height: 70px;
    border-radius: 12px;
    border: 1px solid ${colors.GRAY9};
    transition: all 0.3s ease;
    padding: ${({ isActive }) => (isActive ? "12px 20px 20px 20px" : "20px")};
    background: white;
`;

const Placeholder = styled.p<{ isActive?: boolean }>`
    /*  */
    transition: all 0.3s ease;
    margin-bottom: 4px; // FIXME 해리한테 간격 문의후 수정
    ${({ isActive }) => {
        if (isActive) {
            return css`
                ${typo.BODY_11}
            `;
        } else {
            return css`
                ${typo.BODY_4}
            `;
        }
    }}

    color: ${colors.GRAY6};
`;

export const Column = styled.div`
    /*  */
    display: flex;
    gap: 3px;
`;
